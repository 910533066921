import { useCallback, useMemo, useState } from "react";
import { useClipboard } from "react-aria";
import styled from "@emotion/styled";

import Modal, { useModalState } from "@/components/misc/Modal";
import Button from "@/components/input/Button";
import Icon, { IIconProps } from "@/components/misc/Icon";
import { Body, Heading } from "@/components/Typography";
import {
  ANIMATION_CLASSNAMES,
  overflowEllipsis,
  screenLargerThan,
  screenSmallerThan,
} from "@/styles";
import { squareSizing } from "@/utils/css";

import { ShareContent } from "../Share.types";
import Share from "../Share";

const StyledOption = styled(Button)`
  padding: 0.25rem;
  cursor: pointer;

  .icon-container {
    ${squareSizing("4rem")};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f1ff;
    border-radius: 10rem;
  }

  .label {
    color: #898f9f;
    font-size: 0.8615rem;
    font-weight: 400;
    text-align: left;
  }

  .icon {
    filter: brightness(0);
  }

  &:focus-visible,
  &:focus-within {
    .icon-container {
      background-color: #5f57ff;
    }

    .icon {
      filter: brightness(0) invert(1);
    }

    .label {
      color: #5f57ff;
    }
  }

  ${screenSmallerThan.tablet} {
    display: flex !important;
    border: 1px solid #eceff3;
    border-radius: 0.75rem !important;
    padding: 0.5rem 1.25rem;
    gap: 1.25rem;

    .label {
      flex-grow: 1;
      font-weight: 600;
    }
  }

  ${screenLargerThan.tablet} {
    flex-direction: column;

    .icon-container {
      --size: 4.5rem;
    }
  }
`;

const StyledOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  ${screenSmallerThan.tablet} {
    flex-direction: column;
  }

  ${screenLargerThan.tablet} {
    gap: 3rem;
  }
`;

const StyledShareLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fafafa;
  color: #3d5a8079;

  .content {
    ${overflowEllipsis()};
    flex: 1;
    max-width: calc(100% - 4rem);
  }
`;

const StyledContainer = styled.div`
  position: relative;

  .heading {
    padding-right: 3rem;
  }

  .separator-text {
    text-align: center;
    color: #3d5a80;
    font-weight: 600 !important;
    font-size: 0.75rem;
    opacity: 0.5;
  }
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  top: -0.275rem;
  right: -0.75rem;
  z-index: 2;
`;

const Option = ({
  label,
  icon,
  link,
  onClick,
  iconProps = {},
}: {
  icon: string;
  label: string;
  link?: string;
  onClick?: any;
  iconProps?: Omit<IIconProps, "src">;
}) => (
  <StyledOption openInNewTab variant="ghost" link={link} onClick={onClick}>
    <div className="icon-container">
      <Icon
        className="icon"
        src={icon}
        {...iconProps}
        isSrcRelative
        size="md"
      />
    </div>

    <div className="label">{label}</div>
  </StyledOption>
);

function useShare({ content }: { content: ShareContent }) {
  const { url = "", isUrlRelative } = content;

  const [copied, setCopied] = useState(false);
  const modalState = useModalState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setCopied(false);
      }
    },
  });
  const { clipboardProps } = useClipboard({
    onCopy: () => {
      setCopied(true);
    },
  });

  const handleCopyUrl = useCallback(() => {
    let shareUrl = url;

    if (isUrlRelative) {
      shareUrl = Share.getShareUrl(url);
    }

    Share.copyToClipboard(shareUrl);
    setCopied(true);
  }, [url, isUrlRelative]);

  const shareContent = useMemo(() => Share.getShareContent(content), [content]);

  const modalJsx = (
    <Modal state={modalState} width="480px">
      <StyledContainer>
        <StyledCloseButton useAria variant="ghost" onClick={modalState.close}>
          <Icon
            isSrcRelative
            src="cross.svg"
            customSize="1rem"
            colorVariant="gray"
          />
        </StyledCloseButton>

        <Heading size="lg" className="heading">
          Share via
        </Heading>

        <StyledOptionsContainer className="mt-4">
          {false && (
            <Option
              icon="share/message.svg"
              label="SMS"
              link="sms:?body=Checkout%20this%20awesome%20website%20%40%20http%253A%252F%252Fgilgreenberg.com%252F"
            />
          )}

          <Option
            icon="share/telegram.svg"
            label="Telegram"
            link={shareContent.telegram.url}
          />

          {false && (
            <Option
              icon="share/linkedin.svg"
              label="Linkedin"
              link={
                "https://www.linkedin.com/shareArticle?mini=true&url={mysiteurlvariable}?v={myidvariable}&title=ThisTitle"
              }
            />
          )}

          <Option
            icon="share/whatsapp.svg"
            label="Whatsapp"
            link={shareContent.whatsapp.url}
            data-action="share/whatsapp/share"
            iconProps={{ customSize: "1.75rem" }}
          />

          {Share.isShareSupported() && (
            <Option
              icon="share/share.svg"
              label="More"
              onClick={() => {
                Share.share(content);
              }}
              iconProps={{ customSize: "1.25rem" }}
            />
          )}
        </StyledOptionsContainer>
        {!!url && (
          <>
            <Body size="md" className="mt-5 mb-4 separator-text">
              Or share link
            </Body>

            <StyledShareLink
              role="textbox"
              tabIndex={0}
              {...clipboardProps}
              onClick={handleCopyUrl}
            >
              <div className="content">{shareContent.default.url}</div>
              {copied ? (
                <Icon
                  isSrcRelative
                  className={ANIMATION_CLASSNAMES.FADE_IN}
                  src="tick.svg"
                  colorVariant="primary"
                />
              ) : (
                <Icon
                  isSrcRelative
                  className={ANIMATION_CLASSNAMES.FADE_IN}
                  src="share/copy.svg"
                  colorVariant="primary"
                />
              )}
            </StyledShareLink>
          </>
        )}
      </StyledContainer>
    </Modal>
  );

  const share = useCallback(() => {
    modalState.open();
  }, [modalState]);

  return {
    modalJsx,
    share,
  };
}

export default useShare;
