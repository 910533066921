import { appFeatures } from "@/config/services";
import { MILESTONE_STATUS_CONFIG } from "../ContractsService.config";
import {
  ContractCompleteDetails,
  ContractMilestoneStatus,
  ContractMilestoneStatusNew,
  MilestoneCompleteDetails,
  MilestoneId,
} from "../ContractsService.types";

//----------------------

export function isStatusOrderGraterThan(
  status: ContractMilestoneStatus,
  comparingStatus: ContractMilestoneStatus
) {
  return (
    MILESTONE_STATUS_CONFIG[status].order >
    MILESTONE_STATUS_CONFIG[comparingStatus].order
  );
}

export function isStatusOrderGraterThanOrEqualTo(
  status: ContractMilestoneStatus,
  comparingStatus: ContractMilestoneStatus
) {
  return (
    MILESTONE_STATUS_CONFIG[status].order >=
    MILESTONE_STATUS_CONFIG[comparingStatus].order
  );
}

//----------------------

export function isMilestoneWorkSubmitted(milestone: MilestoneCompleteDetails) {
  return isStatusOrderGraterThanOrEqualTo(
    milestone.status,
    ContractMilestoneStatus.FundRequested
  );
}

export function isMilestoneReviewed(milestone: MilestoneCompleteDetails) {
  return isStatusOrderGraterThanOrEqualTo(
    milestone.status,
    ContractMilestoneStatus.FundReleased
  );
}

export function isMilestoneFunded(milestone: MilestoneCompleteDetails) {
  return isStatusOrderGraterThanOrEqualTo(
    milestone.status,
    ContractMilestoneStatus.Funded
  );
}

export function isMilestonePaymentReleased(
  milestone: MilestoneCompleteDetails
) {
  return [
    ContractMilestoneStatus.Completed,
    ContractMilestoneStatus.FundReleased,
  ].includes(milestone.status);
}

export function getMiestoneStatus(
  milestone: MilestoneCompleteDetails
): ContractMilestoneStatusNew {
  const { status } = milestone;

  if (status === ContractMilestoneStatus.Published) {
    return ContractMilestoneStatusNew.Published;
  }

  if (status === ContractMilestoneStatus.Accepted) {
    return ContractMilestoneStatusNew.Accepted;
  }

  if (status === ContractMilestoneStatus.ReadyForWork) {
    return ContractMilestoneStatusNew.Funded;
  }

  if (status === ContractMilestoneStatus.Review) {
    return ContractMilestoneStatusNew.FundingRequested;
  }

  if (status === ContractMilestoneStatus.Completed) {
    return ContractMilestoneStatusNew.FundingReleased;
  }

  return ContractMilestoneStatusNew.Published;
}

export function isMilestoneFundable(milestone: MilestoneCompleteDetails) {
  if (appFeatures.isSupported("CONTRACT.IMPLICIT_START_MILESTONE")) {
    return [ContractMilestoneStatus.Accepted].includes(milestone.status);
  }

  return [
    ContractMilestoneStatus.Accepted,
    ContractMilestoneStatus.Published,
  ].includes(milestone.status);
}

export function isMilestoneStartable(milestone: MilestoneCompleteDetails) {
  if (appFeatures.isSupported("CONTRACT.IMPLICIT_START_MILESTONE")) {
    return [ContractMilestoneStatus.Published].includes(milestone.status);
  }

  return false;
}

export function isMilestoneWorkSubmittable(
  milestone: MilestoneCompleteDetails
) {
  return [
    ContractMilestoneStatus.ReadyForWork,
    ContractMilestoneStatus.Funded,
  ].includes(milestone.status);
}

export function isMilestoneReviewable(milestone: MilestoneCompleteDetails) {
  return [
    ContractMilestoneStatus.FundRequested,
    ContractMilestoneStatus.Review,
  ].includes(milestone.status);
}

export function isMilestoneCompleted(milestone: MilestoneCompleteDetails) {
  return [
    ContractMilestoneStatus.Completed,
    ContractMilestoneStatus.FundReleased,
  ].includes(milestone.status);
}

export function isMilestoneFundRequestable(
  milestone: MilestoneCompleteDetails
) {
  return [ContractMilestoneStatus.Funded].includes(milestone.status);
}
export function isMilestoneFundReleasable(milestone: MilestoneCompleteDetails) {
  return [
    ContractMilestoneStatus.ReadyForWork,
    ContractMilestoneStatus.Review,
    ContractMilestoneStatus.FundRequested,
  ].includes(milestone.status);
}

//----------------------

export function getNextSubmittableMilestone(contract: ContractCompleteDetails) {
  const index = (contract?.milestones || []).findIndex(
    isMilestoneWorkSubmittable
  );

  return {
    index,
    milestone: index === -1 ? null : contract.milestones[index],
  };
}

export function getNextReviewableMilestone(contract: ContractCompleteDetails) {
  const index = (contract?.milestones || []).findIndex(isMilestoneReviewable);

  return {
    index,
    milestone: index === -1 ? null : contract.milestones[index],
  };
}

export function getNextFundableMilestone(contract: ContractCompleteDetails) {
  const index = (contract?.milestones || []).findIndex(isMilestoneFundable);

  return {
    index,
    milestone: index === -1 ? null : contract.milestones[index],
  };
}

export function getNextStartableMilestone(contract: ContractCompleteDetails) {
  const index = (contract?.milestones || []).findIndex(isMilestoneStartable);

  return {
    index,
    milestone: index === -1 ? null : contract.milestones[index],
  };
}

export function getNextActiveMilestone(contract: ContractCompleteDetails) {
  const nextSubmittableMilestone = getNextSubmittableMilestone(contract);
  const nextReviewableMilestone = getNextReviewableMilestone(contract);
  const nextFundableMilestone = getNextFundableMilestone(contract);
  const nextStartableMilestone = getNextStartableMilestone(contract);

  if (nextSubmittableMilestone?.milestone) {
    return nextSubmittableMilestone;
  }

  if (nextReviewableMilestone?.milestone) {
    return nextReviewableMilestone;
  }

  if (nextFundableMilestone?.milestone) {
    return nextFundableMilestone;
  }

  if (nextStartableMilestone?.milestone) {
    return nextStartableMilestone;
  }

  return {
    index: -1,
    milestone: null,
  };
}

export function getMilestonesMetaData(contract: ContractCompleteDetails) {
  const data: Record<
    MilestoneId,
    {
      workSubmittedDate: string | null;
      paymentRequestedDate: string | null;
      paymentReleasedDate: string | null;
      milestoneCompletionDate: string | null;
      milestoneFundedDate: string | null;
    }
  > = {};

  (contract.milestones || []).forEach(({ id: milestoneId }) => {
    const workSubmittedHistoryItem = (contract.history || []).find(
      ({ action, contract_milestone_id }) =>
        contract_milestone_id === milestoneId &&
        action === "ContractMilestoneSubmitted"
    );
    const workSubmittedDate = workSubmittedHistoryItem?.updated_at || null;
    const paymentRequestedDate = workSubmittedDate;

    const milestoneCompletedHistoryItem = (contract.history || []).find(
      ({ action, contract_milestone_id }) =>
        contract_milestone_id === milestoneId &&
        action === "ContractMilestoneCompleted"
    );
    const milestoneCompletionDate =
      milestoneCompletedHistoryItem?.updated_at || null;
    const paymentReleasedDate = milestoneCompletionDate;

    const milestoneFundedHistoryItem = (contract.history || []).find(
      ({ action, contract_milestone_id }) =>
        contract_milestone_id === milestoneId &&
        action === "ContractMilestoneFunded"
    );
    const milestoneFundedDate = milestoneFundedHistoryItem?.updated_at || null;

    data[milestoneId] = {
      workSubmittedDate,
      paymentRequestedDate,
      milestoneCompletionDate,
      paymentReleasedDate,
      milestoneFundedDate,
    };
  });

  return data;
}
