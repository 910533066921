import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import SplashScreen from "@/features/pages/app/SplashScreen";

import { IPwaContext } from "./Pwa.types";
import { checkIsPwaInstalled } from "./Pwa.utils";

const PwaContext = createContext({} as IPwaContext);

export const usePwa = () => useContext(PwaContext);

export const PwaProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [isPwaInstalled, setIsPwaInstalled] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [installPrompt, setInstallPrompt] = useState<null | Event>(null);

  const updateIsPwaInstalled = useCallback(() => {
    if (document.hidden) {
      return;
    }

    checkIsPwaInstalled()
      .then(setIsPwaInstalled)
      .finally(() => {
        setIsReady(true);
      });
  }, []);

  //-----------------------

  useEffect(() => {
    const handler = (event: Event) => {
      event.preventDefault();
      setInstallPrompt(event);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  useEffect(() => {
    updateIsPwaInstalled();
  }, [updateIsPwaInstalled]);

  useEffect(() => {
    window.addEventListener("visibilitychange", updateIsPwaInstalled);

    return () => {
      window.removeEventListener("visibilitychange", updateIsPwaInstalled);
    };
  }, [updateIsPwaInstalled]);

  //-----------------------

  const triggerInstall = useCallback(async () => {
    if (!installPrompt) {
      return;
    }
  await (installPrompt as any).prompt();
  }, [installPrompt]);

  //-----------------------

  const canInstall = !isPwaInstalled && !!installPrompt;

  if (!isReady) {
    return <SplashScreen />;
  }

  return (
    <PwaContext.Provider
      value={{ isPwaInstalled, isReady, triggerInstall, canInstall }}
    >
      {children}
    </PwaContext.Provider>
  );
};

export default PwaProvider;
