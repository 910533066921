export type Color = {
  hex: string;
};

export type Filter = {
  value: string;
};

export type ThemeColors = {
  neutral: {
    solid: {
      0: Color;
      25: Color;
      50: Color;
      100: Color;
      200: Color;
      300: Color;
      400: Color;
      500: Color;
      600: Color;
      700: Color;
      800: Color;
      900: Color;
    };
  };

  background: {
    white: Color;
    primary: Color;
    0: Color;
    25: Color;
    50: Color;
    100: Color;
    900: Color;
    "primary-lighter": Color;
    "ukwn-1": Color;
    "ukwn-2": Color;
    "ukwn-3": Color;
    "ukwn-4": Color;
    "ukwn-5": Color;
    "ukwn-6": Color;
    "ukwn-7": Color;
    "ukwn-8": Color;
    "ukwn-9": Color;
    "ukwn-10": Color;
    "ukwn-11": Color;
  };

  primary: {
    0: Color;
    25: Color;
    50: Color;
    100: Color;
    200: Color;
    300: Color;
    light: Color;
  };

  icon: {
    text: {
      200: Color;
    };
    300: Color;
    400: Color;
    500: Color;
    600: Color;
    900: Color;
  };

  text: {
    white: Color;
    300: Color;
    400: Color;
    500: Color;
    600: Color;
    900: Color;
    "ukwn-1": Color;
    "ukwn-2": Color;
    "ukwn-3": Color;
    "ukwn-4": Color;
    "ukwn-5": Color;
    "ukwn-6": Color;
    "ukwn-7": Color;
    "ukwn-8": Color;
  };

  border: {
    25: Color;
    50: Color;
    100: Color;
    200: Color;
    "ukwn-1": Color;
    "ukwn-2": Color;
    "ukwn-3": Color;
    "ukwn-4": Color;
    "ukwn-5": Color;
    "ukwn-6": Color;
    "ukwn-7": Color;
    "ukwn-8": Color;
    "ukwn-9": Color;
    "ukwn-10": Color;
    "ukwn-11": Color;
    "ukwn-12": Color;
    "ukwn-13": Color;
    "ukwn-14": Color;
    "ukwn-15": Color;
    "ukwn-16": Color;
    "ukwn-17": Color;
  };

  ukwn: {
    1: Color;
    2: Color;
    3: Color;
  };

  error: {
    400: Color;
    500: Color;
  };

  success: {
    500: Color;
  };

  accent: {
    1: Color;
    2: Color;
    3: Color;
  };
};

export type ThemeFilters = {
  primary: {
    500: Filter;
  };
  white: Filter;
  gray: Filter;
  black: Filter;
};

export enum Theme {
  Light = "Light",
  Dark = "Dark",
}

export type ThemeDetails = {
  id: Theme;
  colors: ThemeColors;
  filters: ThemeFilters;
};

export interface IThemeManagerContext {
  activeTheme: string;
  setActiveTheme: (value: Theme) => void;
  toggleDarkMode: (value?: boolean) => void;
  darkModeEnabled: boolean;
}
