"use client";

import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";

import { Size } from "./Avatar.types";
import { ICON_SIZE_CONFIG } from "./Avatar.config";

export const StyledAvatar = styled.div<{
  $useImage: boolean;
  $size: Size;
  $customSize?: string;
}>`
  position: relative;
  text-transform: uppercase;
  ${({ $size, $customSize }) =>
    squareSizing($customSize || ICON_SIZE_CONFIG[$size].size)}

  border-radius: var(--border-radius-round, 20rem);

  ${({ $useImage }) =>
    $useImage
      ? `
        `
      : `
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: calc(var(--size) * 0.45);
        font-weight: 500;
        line-height: 21px;
        background: var(--clr-background-ukwn-5, #F9A80A);
   `}
`;

export const StyledImage = styled.img<{ $size: Size }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
`;


export const StyledImageInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 3px solid green;
  z-index: 1;
  opacity: 0;
`;