import styled from "@emotion/styled/macro";
import isString from "lodash/isString";

import {
  getViewPortHeightCssString,
  screenLargerThan,
  BREAKPOINT_NAMES,
  screenSmallerThan,
} from "@/styles";

const BREAKPOINT = "smallMonitor" satisfies BREAKPOINT_NAMES;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding-block: 2rem;

  ${screenLargerThan[BREAKPOINT]} {
    gap: 4rem;
    min-height: ${getViewPortHeightCssString()};

    .content {
      flex-grow: 1;
    }
  }

  ${screenSmallerThan[BREAKPOINT]} {
    gap: 3.75rem;
    flex-direction: column;
    justify-content: center;
    padding-block: 5rem;
  }
`;

const StyledSection = styled.section`
  color: #000;

  &:nth-of-type(odd) {
    background-color: #f8f8f8;
  }

  &:nth-of-type(even) {
    background-color: #fff;

    ${screenLargerThan[BREAKPOINT]} {
      ${StyledContainer} {
        flex-direction: row-reverse;
      }

      .content {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
`;

const StyledHeading = styled.h2`
  margin: 0;
  font-size: 2.75rem;
  font-weight: 800;
  line-height: 1.375em;
  max-width: 14ch;

  ${screenLargerThan[BREAKPOINT]} {
    font-weight: 700;
    font-size: 4.25rem;
    line-height: 1.1em;
  }
`;

const StyledContent = styled.p`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  max-width: 52ch;

  ${screenSmallerThan[BREAKPOINT]} {
    margin-top: 1rem;
  }

  ${screenLargerThan[BREAKPOINT]} {
    margin-top: 3rem;
    font-weight: 700;
    line-height: 1.75em;
  }
`;

const StyledIllustration = styled.img`
  width: 100%;

  ${screenSmallerThan[BREAKPOINT]} {
    border-radius: 0.625rem;
  }

  ${screenLargerThan[BREAKPOINT]} {
    border-radius: 1.125rem;
  }
`;

const Section: React.FC<{
  heading: React.ReactNode;
  content: React.ReactNode;
  image: React.ReactNode;
  className?: string;
}> = ({ heading, content, image, className }) => {
  return (
    <StyledSection className={className}>
      <StyledContainer className="container">
        <div className="content">
          <div>
            <StyledHeading>{heading}</StyledHeading>
            <StyledContent>{content}</StyledContent>
          </div>
        </div>
        <div className="illustration">
          {isString(image) ? <StyledIllustration alt="" src={image} /> : image}
        </div>
      </StyledContainer>
    </StyledSection>
  );
};

export default Section;
