import React from "react";
import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";
import Icon from "@/components/misc/Icon";
import {
  BREAKPOINT_NAMES,
  screenLargerThan,
  screenSmallerThan,
  useResponsive,
} from "@/styles";
import ClaimId from "@/features/PaypipeId/ClaimId";

const BREAKPOINT = "smallMonitor" satisfies BREAKPOINT_NAMES;

const StyledContainer = styled.div`
  padding-block: 2rem;

  ${screenLargerThan[BREAKPOINT]} {
    display: flex;
    align-items: center;
    padding-block: 6rem;
  }

  .highlighted-content {
    display: flex;
    align-items: center;
    color: var(--clr-primary-100, #5f57ff);
    background: rgba(95, 87, 255, 0.1);
    padding: 0.25rem;
    padding-inline-end: 0.5rem;
    border-radius: 10rem;
    width: fit-content;

    .icon {
      ${squareSizing("2rem")};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--clr-primary-100, #5f57ff);
      padding: 0.25rem;
      border-radius: 10rem;
      margin-right: 0.5rem;
    }

    ${screenSmallerThan[BREAKPOINT]} {
      margin: 0 auto;
    }
  }

  .title {
    font-size: 2rem;
    font-weight: 600;
    color: #000000;
    text-transform: capitalize;

    b {
      color: var(--clr-primary-100, #5f57ff);
    }

    ${screenSmallerThan[BREAKPOINT]} {
      text-align: center;
      margin-top: 2rem;
    }

    ${screenLargerThan[BREAKPOINT]} {
      font-size: 4rem;
      margin-top: 1rem;
    }
  }

  .content {
    font-size: 0.875rem;
    font-weight: 400;
    color: #000000;

    ${screenSmallerThan[BREAKPOINT]} {
      text-align: center;
      line-height: 1.5em;
      margin-top: 2rem;
    }

    ${screenLargerThan[BREAKPOINT]} {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }

  .content-container {
    ${screenLargerThan[BREAKPOINT]} {
      flex-grow: 4;
      max-width: 50%;
      display: flex;
      justify-content: flex-end;
    }

    ${screenSmallerThan[BREAKPOINT]} {
      padding: 2rem 0 0 0;
    }
  }

  .illustration {
    flex-grow: 1;
    object-fit: contain;

    ${screenLargerThan[BREAKPOINT]} {
      img {
        height: auto;
        width: 55vw;
        margin-right: -15vw;
      }
    }

    ${screenSmallerThan[BREAKPOINT]} {
      margin-top: 3rem;

      img {
        height: auto;
        width: 100%;
      }
    }
  }
`;

const StyledSection = styled.section`
  background: #ffffff;
  overflow: hidden;
`;

const StyledClaimId = styled(ClaimId)`
  ${screenSmallerThan[BREAKPOINT]} {
    & > * {
      &:first-child {
        padding-inline-start: 1.5rem !important;
      }
    }
  }

  ${screenLargerThan[BREAKPOINT]} {
    max-width: 35rem;

    & > *:first-child {
      padding-inline-end: 0.45rem !important;
      border: 1px solid var(--clr-primary-100, #5f57ff);
      padding: 0.75rem 1.25rem;
      background: #f7f7ff;
    }

    button {
      padding-block: 0.75rem;
      border: 1px solid var(--clr-primary-100, #5f57ff);
      margin: 0 !important;
    }
  }
`;

const ClaimIdsection: React.FC<{ className?: string }> = ({ className }) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  return (
    <StyledSection className={className}>
      <StyledContainer className="container">
        <div className="content-container">
          <div>
            <div className="highlighted-content">
              <span className="icon">
                <Icon
                  src="/assets/pages/paypipe-id/claim.svg"
                  colorVariant="white"
                  size="xs"
                />
              </span>
              Launch your Paypipe.me page
            </div>
            <div className="title">Create Your Paypipe.Me Page In Minutes</div>
            <div className="content">
              Create your own Paypipe.me page and simplify your freelance
              business today. Claim your Paypipe ID to get started.
            </div>
            <StyledClaimId
              className="mt-4"
              styles={{
                "--button-color": "var(--clr-text-white, #fff)",
                "--button-bg-color": "var(--clr-primary-100, #5F57FF)",
                "--field-color": "#949494",
                "--field-bg-color": "#f6f6f6",
              }}
            />
          </div>
        </div>

        <div className="illustration">
          <img
            src={
              isScreenSmallerThanTablet
                ? "/assets/pages/landing/claim-mobile.png"
                : "/assets/pages/landing/claim.png"
            }
            alt=""
          />
        </div>
      </StyledContainer>
    </StyledSection>
  );
};

export default ClaimIdsection;
