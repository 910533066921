import { useCallback, useEffect, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  BaseSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";

import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useDrawerState } from "@/components/misc/Drawer";
import Modal from "@/components/misc/Modal";
import Button from "@/components/input/Button";
import TextField from "@/components/input/TextField"; 
import Icon from "@/components/misc/Icon";

import { StyledButton } from "../../plugins/ToolbarPlugin/ToolbarPlugin.styles";
import { getSelectedNode } from "../../plugins/ToolbarPlugin/ToolbarPlugin.utils";
import { isValidUrl } from "../../Wysiwyg.utils";

const LowPriority = 1;

export default function LinkButton() {
  const [editor] = useLexicalComposerContext();
  const editorRef = useRef(editor);
  editorRef.current = editor;

  const [isLink, setIsLink] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
  const editorSelectedEventTypes = useRef(selectedEventTypes);
  editorSelectedEventTypes.current = selectedEventTypes;

  const [lastSelection, setLastSelection] = useState<BaseSelection | null>(
    null
  );

  const drawerState = useDrawerState({
    onOpenChange: (isOpen) => {
      if (!isOpen) {
        setIsLink(false);
        setLinkUrl("");
        setLastSelection(null);
      }
    },
  });

  //-------------------------------

  const handleLinkClick = useCallback(() => {
    if (!isLink) {
      setLinkUrl("https://");
    }

    drawerState.open();
  }, [isLink, drawerState]);

  const handleUpdate = useCallback(() => {
    if (lastSelection !== null) {
      if (linkUrl) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
      } else {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }
    }
  }, [editor, linkUrl, lastSelection]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleUpdate();
      }
    },
    [handleUpdate]
  );

  const handleDoneClick = useCallback(() => {
    handleUpdate();
    drawerState.close();
  }, [handleUpdate, drawerState]);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    let allSelectedEvents = [...editorSelectedEventTypes.current];

    let isLink = false;
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      const isParentLinkNode = $isLinkNode(parent);
      const isNodeLinkNode = $isLinkNode(node);

      if (isParentLinkNode || isNodeLinkNode) {
        if (!allSelectedEvents.includes("formatInsertLink")) {
          allSelectedEvents.push("formatInsertLink");
        }

        if (isParentLinkNode) {
          setLinkUrl(parent.getURL());
        } else if (isNodeLinkNode) {
          setLinkUrl(node.getURL());
        }

        if (!selection.isCollapsed()) {
          isLink = true;
        }
        setIsLink(true);
      } else {
        if (allSelectedEvents.includes("formatInsertLink")) {
          allSelectedEvents = allSelectedEvents.filter(
            (ev) => ev !== "formatCode"
          );
        }
        setLinkUrl("");
        setIsLink(false);
      }
    }

    setSelectedEventTypes(allSelectedEvents);
    editorSelectedEventTypes.current = allSelectedEvents;

    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection !== null &&
      (isLink ? true : !nativeSelection.isCollapsed) &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      setLastSelection(selection);
    }

    return true;
  }, [editor]);

  //-------------------------------

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  //-------------------------------

  const isLinkUrlValid = isValidUrl(linkUrl);
  const disableDoneButton = !!linkUrl && !isLinkUrlValid;

  const drawerContent = (
    <>
      <TextField
        autoFocus
        type="search"
        variant="background"
        value={linkUrl}
        onChange={setLinkUrl}
        onKeyDown={handleKeyDown}
      />

      <Button
        disabled={disableDoneButton}
        colorVariant={disableDoneButton ? "disabled" : "primary"}
        className="w-100 mt-4"
        tabIndex={0}
        onMouseDown={(event) => event.preventDefault()}
        onClick={handleDoneClick}
      >
        Done
      </Button>
    </>
  );

  return (
    <>
      <Modal state={drawerState}>
        <div className="px-3">{drawerContent}</div>
      </Modal>
      <StyledButton
        onClick={handleLinkClick}
        $active={isLink}
        aria-label="Format link"
      >
        <Icon
          isSrcRelative
          src="link.svg"
          size="xs"
          colorVariant={isLink ? "primary" : "black"}
        />
      </StyledButton>
    </>
  );
}
