import React from "react";

import Logo from "@/features/app/Logo";

import { StyledHeader, StyledLogoContainer } from "./Header.styles";
import { IHeaderProps } from "./Header.types";

const Header: React.FC<IHeaderProps> = ({
  logo: { component: logoComponent } = { component: undefined },
  appendContent,
  prependContent,
}) => {
  return (
    <StyledHeader>
      <div className="container">
        {prependContent?.children}

        <StyledLogoContainer>
          {logoComponent === undefined ? (
            <Logo variant="text" />
          ) : (
            logoComponent
          )}
        </StyledLogoContainer>

        {appendContent?.children}
      </div>
    </StyledHeader>
  );
};

export default Header;
