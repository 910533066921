import React from "react";
import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";
import Icon from "@/components/misc/Icon";
import { screenLargerThan, screenSmallerThan } from "@/styles";
import ClaimId from "@/features/PaypipeId/ClaimId";

const StyledContainer = styled.div`
  background: #ffffff;
  border-radius: 2.25rem;
  overflow: hidden;

  ${screenLargerThan.tablet} {
    display: flex;
    align-items: center;
    padding: 3rem 4rem 4rem 4rem;
  }

  .highlighted-content {
    display: flex;
    align-items: center;
    color: var(--clr-primary-100, #5f57ff);
    background: rgba(95, 87, 255, 0.1);
    padding: 0.25rem;
    padding-inline-end: 0.5rem;
    border-radius: 10rem;
    width: fit-content;

    .icon {
      ${squareSizing("2rem")};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--clr-primary-100, #5f57ff);
      padding: 0.25rem;
      border-radius: 10rem;
      margin-right: 0.5rem;
    }

    ${screenSmallerThan.tablet} {
      margin: 0 auto;
    }
  }

  .title {
    font-size: 4rem;
    font-weight: 400;
    color: #000000;
    text-transform: capitalize;

    b {
      color: var(--clr-primary-100, #5f57ff);
    }

    ${screenSmallerThan.tablet} {
      text-align: center;
    }
  }

  .content {
    font-size: 1.5rem;
    font-weight: 400;
    color: #000000;

    ${screenSmallerThan.tablet} {
      text-align: center;
    }
  }

  .content-container {
    ${screenLargerThan.tablet} {
      width: 40%;
      max-width: 32rem;
    }

    ${screenSmallerThan.tablet} {
      padding: 3rem 1.25rem 0 1.25rem;
    }
  }

  .illustration {
    object-fit: contain;

    ${screenLargerThan.tablet} {
      height: 100%;
      width: auto;
      margin-bottom: -4rem;
      max-width: 60%;
    }

    ${screenSmallerThan.tablet} {
      margin-top: 2rem;
      height: auto;
      width: 100%;
    }
  }
`;

const ClaimIdsection: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <div className="content-container">
        <div className="highlighted-content">
          <span className="icon">
            <Icon
              src="/assets/pages/paypipe-id/claim.svg"
              colorVariant="white"
              size="xs"
            />
          </span>
          Claim your Paypipe ID now!
        </div>

        <div className="title mt-3">
          Want this to be <b>you?</b>
        </div>

        <div className="content mt-3">
          Create your own Paypipe profile and begin simplifying your work
          process today.
        </div>

        <ClaimId
          buttonInside
          className="mt-4"
          styles={{
            "--button-color": "var(--clr-text-white, #fff)",
            "--button-bg-color": "var(--clr-primary-100, #5F57FF)",
            "--field-color": "#949494",
            "--field-bg-color": "#f6f6f6",
          }}
        />
      </div>

      <img
        className="illustration"
        src="assets/pages/paypipe-id/cta-illustration.png"
        alt=""
      />
    </StyledContainer>
  );
};

export default ClaimIdsection;
