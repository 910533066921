import React from "react";
import styled from "@emotion/styled";

import Icon from "@/components/misc/Icon";
import { screenSmallerThan } from "@/styles";

//-----------------------------

const StyledHeading = styled.h3<{ $centerOnPhone: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  color: #7d7d7d;
  text-transform: uppercase;
  margin: 0;

  .icon {
    filter: invert(51%) sepia(10%) saturate(15%) hue-rotate(5deg)
      brightness(93%) contrast(90%);
  }

  ${screenSmallerThan.tablet} {
    ${({ $centerOnPhone }) =>
      $centerOnPhone
        ? `
            display: flex;
            justify-content: center;
          `
        : ""}
  }
`;

//-----------------------------

type ISectionProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
  icon?: string;
  className?: string;
  isIconSrcRelative?: boolean;
};

//-----------------------------

const Section: React.FC<ISectionProps> = ({
  title,
  children,
  icon,
  className,
  isIconSrcRelative = false,
}) => {
  return (
    <section className={className}>
      <StyledHeading $centerOnPhone={!!icon}>
        {!!icon && (
          <Icon
            isSrcRelative={isIconSrcRelative}
            className="icon"
            src={icon}
            size="md"
          />
        )}
        {title}
      </StyledHeading>
      <div className="mt-3">{children}</div>
    </section>
  );
};

export default Section;
