"use client";

import React from "react";
import styled from "@emotion/styled";

import Header from "@/features/app/HeaderNew";

const StyledContainer = styled.div`
  --header-height: 5rem;
`;

const StyledMain = styled.main``;

const MainLayout: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <StyledContainer className={className}>
      <StyledMain>
        <Header />
        {children}
      </StyledMain>
    </StyledContainer>
  );
};

export default MainLayout;
