"use client";

import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";

export const StyledLoader = styled.div`
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  ) !important;
  background-repeat: no-repeat;
  background-size: 800px 800px !important;
  border-radius: 3px;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  -webkit-animation-timing-function: linear;

  @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
`;

export const StyledCircularLoader = styled(StyledLoader)<{ $size: string }>`
  ${({ $size }) => squareSizing($size)};
  border-radius: 10rem;
`;
