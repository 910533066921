import { Variant } from "./Logo.types";

export const LOGO_CONFIG: Record<
  Variant,
  {
    src: string;
    width: string;
    height: string;
    desktopWidth: string;
    desktopHeight: string;
  }
> = {
  plain: {
    src: "/assets/images/branding/logo.svg",
    width: "6.375rem",
    height: "1.5rem",
    desktopWidth: "8.75rem",
    desktopHeight: "2rem",
  },

  gradient: {
    src: "/assets/images/branding/logo_gradient.svg",
    width: "7rem",
    height: "1.75rem",
    desktopWidth: "9.75rem",
    desktopHeight: "2.5rem",
  },

   text: {
    src: "/assets/images/branding/logo_with_text.svg",
    width: "6.5rem",
    height: "1.5rem",
    desktopWidth: "7.5rem",
    desktopHeight: "1.615rem",
  },
};
