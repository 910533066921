import React from "react";
import styled from "@emotion/styled";

import SkllCard from "@/features/PaypipeId/SkillCard";
import { SkillDetails } from "@/services/UserService";
import { screenLargerThan } from "@/styles";

const StyledContainer = styled.div`
  display: grid;
  gap: 1rem;

  ${screenLargerThan.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }

  ${screenLargerThan.widescreen} {
    gap: 1.5rem;
  }
`;

const Skills: React.FC<{
  skills: SkillDetails[];
  className?: string;
}> = ({ skills, className }) => {
  if (!skills.length) {
    return null;
  }

  return (
    <StyledContainer className={className}>
      {skills.map((s, i) => (
        <SkllCard key={i} skill={s} />
      ))}
    </StyledContainer>
  );
};

export default Skills;
