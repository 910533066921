export const CONFIG = {
  KINDE_DOMAIN: process.env.REACT_APP_KINDE_DOMAIN!,
  KINDE_CLIENT_ID: process.env.REACT_APP_KINDE_CLIENT_ID!,
  KINDE_CALLBACK_URL: process.env.REACT_APP_KINDE_CALLBACK_URL!,
  KINDE_LOGOUT_URL: process.env.REACT_APP_KINDE_LOGOUT_REDIRECT_URL!,
  KINDE_CONNECTION_ID: process.env.REACT_APP_KINDE_CONNECTION_ID!,
  KINDE_GOOGLE_CONNECTION_ID: process.env.REACT_APP_KINDE_GOOGLE_CONNECTION_ID!,
  KINDE_FB_CONNECTION_ID: process.env.REACT_APP_KINDE_FB_CONNECTION_ID!,
  KINDE_LINKEDIN_CONNECTION_ID:
    process.env.REACT_APP_KINDE_LINKEDIN_CONNECTION_ID!,
};

export const PAYPIPE_ID_SEARCH_PARAM_NAME = "paypipe_id";
