import React from "react";
import {
  Overlay,
  useModalOverlay,
  useOverlayTrigger,
  usePreventScroll,
} from "react-aria";

import { ANIMATION_CLASSNAMES, useResponsive } from "@/styles";
import { joinClassNames } from "@/utils/classNames";
import Icon from "@/components/misc/Icon";

import {
  StyledCloseButton,
  StyledContainer,
  StyledContent,
  StyledModal,
} from "./Modal.styles";
import { IModalProps } from "./Modal.types";

const Modal: React.FC<IModalProps> = ({
  state,
  children,
  isDismissable = true,
  width: widthFromProps = "560px",
  fullscreen,
  className,
  desktopProps,
  mobileProps,
  containerProps,
  urlHash,
  allowScroll,
  showCloseButton = false,
  contentContainerProps = {},
  ...restProps
}) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const ref = React.useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(
    { isDismissable, ...restProps },
    state,
    ref
  );
  const { overlayProps } = useOverlayTrigger({ type: "dialog" }, state);

  usePreventScroll({ isDisabled: !state.isOpen });

  if (!state.isOpen) {
    return null;
  }

  let isFullscreen = fullscreen;
  let width = widthFromProps;
  let screenClassName = "";
  if (isScreenSmallerThanTablet) {
    if (mobileProps) {
      if (mobileProps?.fullscreen) {
        isFullscreen = mobileProps?.fullscreen;
      }
      if (mobileProps?.width) {
        width = mobileProps?.width;
      }
      if (mobileProps?.className) {
        screenClassName = mobileProps?.className;
      }
    }
  } else {
    if (desktopProps) {
      if (desktopProps?.fullscreen) {
        isFullscreen = desktopProps?.fullscreen;
      }
      if (desktopProps?.width) {
        width = desktopProps?.width;
      }
      if (desktopProps?.className) {
        screenClassName = desktopProps?.className;
      }
    }
  }

  return (
    <Overlay>
      <StyledModal {...underlayProps} style={containerProps?.style}>
        <StyledContainer
          {...modalProps}
          {...contentContainerProps}
          className={joinClassNames(
            className,
            screenClassName,
            modalProps.className,
            ANIMATION_CLASSNAMES.FADE_IN_FROM_TOP
          )}
          ref={ref}
          style={{
            ...(isFullscreen
              ? {
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  borderRadius: "0",
                }
              : {
                  width,
                  maxWidth: "calc(100% - 2rem)",
                  maxHeight: "calc(100% - 2rem)",
                }),
            ...(allowScroll
              ? {
                  overflow: "hidden auto",
                }
              : {
                  overflow: "auto",
                }),
            ...(contentContainerProps?.style || {}),
          }}
        >
          <StyledContent>
            {!!children && React.cloneElement(<>{children}</>, overlayProps)}
          </StyledContent>

          {showCloseButton && (
            <StyledCloseButton
              className="close-button"
              variant="ghost"
              onClick={state.close}
            >
              <Icon
                isSrcRelative
                size="xs"
                src="cross.svg"
                colorVariant="gray"
              />
            </StyledCloseButton>
          )}
        </StyledContainer>
      </StyledModal>
    </Overlay>
  );
};

export default Modal;
