import {
  AmazonLogo,
  AngularLogo,
  AppleLogo,
  ArticleNyTimes,
  Camera,
  CastleTurret,
  CookingPot,
  Cube,
  FigmaLogo,
  FileJs,
  FileVue,
  GoogleLogo,
  Heartbeat,
  LockKey,
  MoneyWavy,
  MusicNote,
  NavigationArrow,
  PaypalLogo,
  PenNibStraight,
  Ranking,
  SquaresFour,
  StripeLogo,
  TestTube,
} from "@phosphor-icons/react";

export const COMMON_SKILLS_CONFIG: Record<
  string,
  { iconJsx?: React.ReactNode; bgColor?: string }
> = {
  testing: {
    iconJsx: <TestTube size={32} color="#fff" />,
  },
  "3d": {
    iconJsx: <Cube size={32} color="#fff" />,
  },
  writing: {
    iconJsx: <PenNibStraight size={32} color="#fff" />,
  },
  video: {
    iconJsx: <Camera size={32} color="#fff" />,
  },
  angular: {
    iconJsx: <AngularLogo size={32} color="#fff" />,
  },
  "angular.js": {
    iconJsx: <AngularLogo size={32} color="#fff" />,
  },
  js: {
    iconJsx: <FileJs size={32} color="#fff" />,
  },
  figma: {
    iconJsx: <FigmaLogo size={32} color="#fff" />,
  },
  microsoft: {
    iconJsx: <SquaresFour size={32} color="#fff" />,
  },
  seo: {
    iconJsx: <Ranking size={32} color="#fff" />,
  },
  amazon: {
    iconJsx: <AmazonLogo size={32} color="#fff" />,
  },
  apple: {
    iconJsx: <AppleLogo size={32} color="#fff" />,
  },
  article: {
    iconJsx: <ArticleNyTimes size={32} color="#fff" />,
  },
  music: {
    iconJsx: <MusicNote size={32} color="#fff" />,
  },
  audio: {
    iconJsx: <MusicNote size={32} color="#fff" />,
  },
  sound: {
    iconJsx: <MusicNote size={32} color="#fff" />,
  },
  financial: {
    iconJsx: <MoneyWavy size={32} color="#fff" />,
  },
  food: {
    iconJsx: <CookingPot size={32} color="#fff" />,
  },
  game: {
    iconJsx: <CastleTurret size={32} color="#fff" />,
  },
  google: {
    iconJsx: <GoogleLogo size={32} color="#fff" />,
  },
  gps: {
    iconJsx: <NavigationArrow size={32} color="#fff" />,
  },
  health: {
    iconJsx: <Heartbeat size={32} color="#fff" />,
  },
  medical: {
    iconJsx: <Heartbeat size={32} color="#fff" />,
  },
  security: {
    iconJsx: <LockKey size={32} color="#fff" />,
  },
};

export const SKILLS_CONFIG: Record<
  string,
  { iconJsx?: React.ReactNode; bgColor?: string }
> = {
  PayPal: {
    iconJsx: <PaypalLogo size={32} color="#fff" />,
  },
  Stripe: {
    iconJsx: <StripeLogo size={32} color="#fff" />,
  },
  "Vue.js": {
    iconJsx: <FileVue size={32} color="#fff" />,
  },
};
