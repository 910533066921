import { useMemo } from "react";
import useSWR from "swr";

import { userService } from "@/config/services";
import { parseProfileData } from "../UserService.utils";

function usePaypipeIdProfile(paypieId: string) {
  const {
    data: accountData,
    isLoading,
    error,
  } = useSWR(
    paypieId ? `user/by-paypipe-id/${paypieId}` : null,
    () => userService.getUserByPaypipeId(paypieId).then((res) => res.data.data),
    {
      errorRetryInterval: 10000,
      errorRetryCount: 5,
    }
  );

  const profileData = useMemo(
    () => parseProfileData(accountData?.details || ""),
    [accountData]
  );

  return { accountData, profileData, isLoading, hasError: error };
}

export default usePaypipeIdProfile;
