import styled from "@emotion/styled/macro";

import {
  getViewPortHeightCssString,
  screenLargerThan,
  BREAKPOINT_NAMES,
  screenSmallerThan,
} from "@/styles";
import Button from "@/components/input/Button";
import { getClaimIdUrl } from "@/services/UserService";

import UserCards from "./UserCards";

const BREAKPOINT = "smallMonitor" satisfies BREAKPOINT_NAMES;

const StyledContainer = styled.div`
  position: relative;
  background-image: url(/assets/pages/landing/user-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: calc(${getViewPortHeightCssString()} - 6rem);
  box-shadow: 0px 1.98074px 44.45px rgba(0, 0, 0, 0.19);
  border-radius: 0.25rem;

  .content {
    position: relative;
    z-index: 1;

    text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }

  ${screenLargerThan[BREAKPOINT]} {
    display: flex;
    align-items: center;
    gap: 4rem;
    padding: 3rem !important;
    padding-bottom: 6rem !important;
    position: relative;
    min-height: calc(${getViewPortHeightCssString()} - 11rem);

    .content {
      flex-grow: 1;
    }
  }

  ${screenSmallerThan[BREAKPOINT]} {
    flex-direction: column;
    justify-content: center;
    padding: 0 !important;
    padding-bottom: 2rem !important;

    .content {
      padding: 4rem 1rem 2rem 1rem;
      margin-bottom: 2rem;
    }
  }
`;

const StyledSection = styled.section`
  color: #fff;

  ${screenLargerThan[BREAKPOINT]} {
    display: flex;
    align-items: center;
    padding-block: 5.5rem;
  }
`;

const StyledHeading = styled.h2`
  margin: 0;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.125em;
  max-width: 12ch;

  ${screenSmallerThan[BREAKPOINT]} {
    margin-top: 1.5rem;
  }

  ${screenLargerThan[BREAKPOINT]} {
    margin-top: 1.5rem;
    font-size: 4.25rem;
  }
`;

const StyledContent = styled.p`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: 0.5px;
  max-width: 44ch;

  ${screenSmallerThan[BREAKPOINT]} {
    margin-top: 1rem;
  }

  ${screenLargerThan[BREAKPOINT]} {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    line-height: 1.5em;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 10rem;
  padding: 1rem;
  margin-top: 2rem;
  font-size: 1rem;
  color: #000;
`;

//------------------------

const UserSection: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <StyledSection className={className}>
      <StyledContainer className="container">
        <div className="content">
          <StyledHeading>Is Paypipe.me for me?</StyledHeading>
          <StyledContent>
            Paypipe.me is the perfect landing page for any type of independent
            professional looking to make it easier to engage with clients and
            get paid on time.
          </StyledContent>
          <StyledButton
            openInNewTab
            link={getClaimIdUrl()}
            colorVariant="white-primary"
            borderRadius="round"
          >
            Create your free Paypipe.me page
          </StyledButton>
        </div>

        <UserCards />
      </StyledContainer>
    </StyledSection>
  );
};

export default UserSection;
