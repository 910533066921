"use client";

import React from "react";

import ClaimId from "@/features/PaypipeId/ClaimId";

import {
  StyledPage,
  StyledContentSection,
  StyledHeading,
  StyledImage,
  StyledSmallText,
  StyledSubHeading,
  StyledContainer,
} from "./PaypipeId404Page.styles";
import DesktopPrimaryLayout from "@/features/layouts/DesktopPrimaryLayout";
import { useResponsive } from "@/styles";

const PaypipeId404Page: React.FC = () => {
  const { isScreenSmallerThanTablet } = useResponsive();

  return (
    <DesktopPrimaryLayout>
      <StyledPage>
        <StyledContainer className="container">
          <StyledContentSection>
            <StyledSmallText>Profile not available!</StyledSmallText>
            <StyledHeading>Hey Buddy</StyledHeading>
            <StyledSubHeading>
              We can't seem to find the profile you are looking for. Check back
              in later.
            </StyledSubHeading>
            {!isScreenSmallerThanTablet && <ClaimId />}
          </StyledContentSection>

          <StyledImage src="assets/pages/paypipe-id-404/ghost.png" alt="" />
          {isScreenSmallerThanTablet && <ClaimId />}
        </StyledContainer>
      </StyledPage>
    </DesktopPrimaryLayout>
  );
};

export default PaypipeId404Page;
