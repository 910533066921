import React from "react";
import styled from "@emotion/styled";

import { Specialization } from "@/services/UserService";
import ExperienceCard from "@/features/PaypipeId/ExperienceCard";

const StyledContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
`;

const Experiences: React.FC<{
  experiences: Specialization[];
  className?: string;
}> = ({ experiences, className }) => {
  if (!experiences.length) {
    return null;
  }

  return (
    <StyledContainer className={className}>
      {experiences.map((s, i) => (
        <ExperienceCard key={i} experience={s} />
      ))}
    </StyledContainer>
  );
};

export default Experiences;
