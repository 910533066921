import { BorderRadius, ButtonVariant, ColorVariant } from "./Button.types";

export const BUTTON_VARAINT_CONFIG: Record<
  ColorVariant,
  Record<
    ButtonVariant,
    {
      color: string;
      bgColor: string;
      borderColor: string;
      disabledColor?: string;
      disabledBgColor?: string;
      disabledBorderColor?: string;
      styles?: React.CSSProperties;
    }
  >
> = {
  primary: {
    primary: {
      color: "var(--clr-text-white, #fff)",
      bgColor: "var(--clr-primary-100, #5F57FF)",
      borderColor: "var(--clr-primary-100, #5F57FF)",
      disabledColor: "var(--clr-ukwn-3, #A4ACB9)",
      disabledBgColor: "var(--clr-background-disabled, #F8FAFB)",
      disabledBorderColor: "var(--clr-background-disabled, #F8FAFB)",
    },
    secondary: {
      color: "var(--clr-primary-100, #5F57FF)",
      bgColor: "transparent",
      borderColor: "var(--clr-primary-100, #5F57FF)",
      disabledColor: "var(--clr-background-disabled, #F8FAFB)",
      disabledBorderColor: "var(--clr-background-disabled, #F8FAFB)",
    },
    ghost: {
      color: "var(--clr-primary-100, #5F57FF)",
      bgColor: "transparent",
      borderColor: "transparent",
      disabledColor: "var(--clr-background-disabled, #F8FAFB)",
    },
  },

  "gradient-primary": {
    primary: {
      color: "var(--clr-text-white, #fff)",
      bgColor: "var(--clr-primary-100, #5F57FF)",
      borderColor: "transparent",
      disabledColor: "var(--clr-ukwn-3, #A4ACB9)",
      disabledBgColor: "var(--clr-background-disabled, #F8FAFB)",
      disabledBorderColor: "var(--clr-background-disabled, #F8FAFB)",
      styles: {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%)",
        backgroundColor: "var(--bg-color)",
      },
    },
    secondary: {
      color: "var(--clr-primary-100, #5F57FF)",
      bgColor: "transparent",
      borderColor: "var(--clr-primary-100, #5F57FF)",
      disabledColor: "var(--clr-background-disabled, #F8FAFB)",
      disabledBorderColor: "var(--clr-background-disabled, #F8FAFB)",
    },
    ghost: {
      color: "var(--clr-primary-100, #5F57FF)",
      bgColor: "transparent",
      borderColor: "transparent",
      disabledColor: "var(--clr-background-disabled, #F8FAFB)",
    },
  },

  "white-primary": {
    primary: {
      color: "var(--clr-primary-100, #5F57FF)",
      bgColor: "#fff",
      borderColor: "#fff",
    },
    secondary: {
      color: "#fff",
      bgColor: "transparent",
      borderColor: "#fff",
    },
    ghost: {
      color: "#fff",
      bgColor: "transparent",
      borderColor: "transparent",
    },
  },

  black: {
    primary: {
      color: "var(--clr-text-white, #fff)",
      bgColor: "var(--clr-text-900, #0d0d12)",
      borderColor: "var(--clr-text-900, #0d0d12)",
    },
    secondary: {
      color: "var(--clr-text-900, #0d0d12)",
      bgColor: "transparent",
      borderColor: "var(--clr-text-900, #0d0d12)",
    },
    ghost: {
      color: "var(--clr-text-900, #0d0d12)",
      bgColor: "transparent",
      borderColor: "transparent",
    },
  },

  danger: {
    primary: {
      color: "var(--clr-text-white, #fff)",
      bgColor: "var(--clr-error-400, #DF1C41)",
      borderColor: "var(--clr-error-400, #DF1C41)",
    },
    secondary: {
      color: "var(--clr-error-400, #DF1C41)",
      bgColor: "var(--clr-background-white, #fff)",
      borderColor: "var(--clr-error-400, #DF1C41)",
    },
    ghost: {
      color: "var(--clr-error-400, #DF1C41)",
      bgColor: "transparent",
      borderColor: "transparent",
    },
  },

  gray: {
    primary: {
      color: "var(--clr-text-900, #0d0d12)",
      bgColor: "var(--clr-background-ukwn-4, #DFE1E7)",
      borderColor: "var(--clr-border-ukwn-5, #DFE1E7)",
    },
    secondary: {
      color: "var(--clr-text-900, #0d0d12)",
      bgColor: "var(--clr-background-white, #fff)",
      borderColor: "var(--clr-border-ukwn-5, #DFE1E7)",
    },
    ghost: {
      color: "var(--clr-icon-text-200, #818898)",
      bgColor: "transparent",
      borderColor: "transparent",
    },
  },

  disabled: {
    primary: {
      color: "var(--clr-ukwn-3, #A4ACB9)",
      bgColor: "var(--clr-background-disabled, #F8FAFB)",
      borderColor: "var(--clr-background-disabled, #F8FAFB)",
    },
    secondary: {
      color: "var(--clr-background-disabled, #F8FAFB)",
      bgColor: "transparent",
      borderColor: "var(--clr-background-disabled, #F8FAFB)",
    },
    ghost: {
      color: "var(--clr-background-disabled, #F8FAFB)",
      bgColor: "transparent",
      borderColor: "transparent",
    },
  },
};

export const DEFAULT_BUTTON_VARIANT: ButtonVariant = "primary";

export const DEFAULT_COLOR_VARIANT: ColorVariant = "primary";

export const BUTTON_BORDER_RADIUS: Partial<
  Record<BorderRadius, { cssVariable: string }>
> = {
  round: {
    cssVariable: "--border-radius-round",
  },
};

export const DEFAULT_BORDER_RADIUS: BorderRadius = "round";
