import styled from "@emotion/styled/macro";

import ClaimId from "@/features/PaypipeId/ClaimId";

import {
  getViewPortHeightCssString,
  screenLargerThan,
  BREAKPOINT_NAMES,
  screenSmallerThan,
} from "@/styles";

const BREAKPOINT = "smallMonitor" satisfies BREAKPOINT_NAMES;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding-block: 2rem;

  ${screenLargerThan[BREAKPOINT]} {
    gap: 4rem;
    align-items: flex-start;

    .content {
      flex-grow: 1;
    }
  }

  ${screenSmallerThan[BREAKPOINT]} {
    gap: 4rem;
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledSection = styled.section`
  background-image: url(/assets/pages/landing/hero-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  color: #fff;
  min-height: ${getViewPortHeightCssString()};
  padding-top: calc(var(--header-height) + 1rem);

  ${screenLargerThan[BREAKPOINT]} {
    display: flex;
    align-items: center;
  }
`;

const StyledSecondaryText = styled.p`
  margin: 0;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.5em;

  ${screenLargerThan[BREAKPOINT]} {
    line-height: 1.25em;
    font-size: 1.5rem;
  }
`;

const StyledHeading = styled.h2`
  margin: 0;
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.375em;
  max-width: 12ch;

  ${screenSmallerThan[BREAKPOINT]} {
    margin-top: 1.5rem;
  }

  ${screenLargerThan[BREAKPOINT]} {
    margin-top: 1.5rem;
    font-size: 6rem;
    line-height: 1.125em;
  }
`;

const StyledContent = styled.p`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  max-width: 46ch;
  opacity: 0.75;

  b {
    font-weight: 700;
  }

  ${screenSmallerThan[BREAKPOINT]} {
    margin-top: 1rem;
  }

  ${screenLargerThan[BREAKPOINT]} {
    margin-top: 1.5rem;
    line-height: 1.625em;
    font-size: 1.375rem;
  }
`;

const StyledIllustration = styled.img`
  width: 100%;
  height: auto;
`;

const StyledClaimId = styled(ClaimId)`
  --button-color: #fff !important;
  --button-bg-color: var(--clr-primary-100, #5f57ff) !important;
  --field-color: #00000080 !important;
  --field-bg-color: #f8f8f8 !important;

  & > * {
    &:first-child {
      padding-inline: 1.5rem !important;
    }
  }

  ${screenSmallerThan[BREAKPOINT]} {
    margin-top: 2rem;

    & > * {
      width: 100%;

      &:first-child {
        padding-block: 0.625rem;
      }

      &:last-child {
        padding-block: 1.25rem;
        margin-top: 0.75rem;
      }
    }
  }

  ${screenLargerThan[BREAKPOINT]} {
    margin-top: 3rem;
    max-width: 37rem;

    & > * {
      padding-block: 0.75rem !important;
    }
  }
`;

const HeroSection: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <StyledSection id="home" className={className}>
      <StyledContainer className="container">
        <div className="content">
          <StyledSecondaryText>
            Supercharge Your Freelance Business
          </StyledSecondaryText>
          <StyledHeading>One Page To Rule Them All</StyledHeading>
          <StyledContent>
            List your <b>services</b>, <b>portfolio</b>, and <b>experiences</b>,{" "}
            <b>manage clients</b> and
            <b>get paid</b> securely , All in one place. Get started by claiming
            your unique Paypipe ID for free.
          </StyledContent>
          <StyledClaimId
            buttonInside={false}
            buttonProps={{ variant: "primary", colorVariant: "primary" }}
            textFieldProps={{ scrollToTopOnFocus: false }}
          />
        </div>
        <div className="illustration">
          <StyledIllustration alt="" src="/assets/pages/landing/hero.png" />
        </div>
      </StyledContainer>
    </StyledSection>
  );
};

export default HeroSection;
