import { DEVICE_BREAKPOINT } from "@/styles";

import { FEATURE_FLAGS } from "./AppFeatures.config";
import { FeatureName } from "./AppFeatures.types";

class AppFeatures {
  getScreenWidth() {
    try {
      return window.innerWidth;
    } catch {
      return 0;
    }
  }

  isOnMobile() {
    return this.getScreenWidth() < DEVICE_BREAKPOINT.tablet;
  }

  isSupported(name: FeatureName) {
    const config = FEATURE_FLAGS[name];

    if (config?.supported) {
      return true;
    }

    if (this.isOnMobile()) {
      if (config?.supportedOnMobile) {
        return true;
      }
    } else {
      if (config?.supportedOnDesktop) {
        return true;
      }
    }

    return false;
  }
}

export default AppFeatures;
