import * as amplitude from "@amplitude/analytics-browser";

import { APP_VERSION, IS_STAGING_ENV } from "@/config/app";
import {
  EventProps,
  ShareOfferEventArgs,
  OfferEvents,
  OfferFormEventArgs,
  ShareOfferEvents,
  NotifyEvents,
  NotifyEventsArgs,
  RejectOfferEvents,
  FeedbackEvents,
  FeedbackEventsArgs,
} from "./Analytics.types";

const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY!;

declare const window: Window & {
  gtag: Function;
};

export class Analytics {
  constructor() {
    try {
      window.gtag("config", "TAG_ID", {
        send_page_view: true,
      });
    } catch {}

    amplitude.init(AMPLITUDE_KEY, {
      autocapture: true,
      minIdLength: 1,
    });

    const identify = new amplitude.Identify();
    identify.set("app_version", APP_VERSION);
    amplitude.identify(identify);
  }

  //----------------------

  setUser = (data?: {
    username?: string;
    id?: string | number;
    email?: string;
  }) => {
    if (data) {
      const { id, email, username } = data;
      if (id) {
        amplitude.setUserId(`${id}`);
      }

      const identify = new amplitude.Identify();
      if (id) identify.set("id", id);
      if (email) identify.set("email", email);
      if (username) identify.set("username", username);
      identify.set("app_version", APP_VERSION);
      amplitude.identify(identify);
    }
  };

  clearUser = () => {
    amplitude.identify(new amplitude.Identify().clearAll());
    amplitude.setUserId("");
  };

  triggerAmplitudeEvent = ({ event, eventArgs }: EventProps) => {
    try {
      amplitude.track(event, eventArgs, {
        time: Date.now(),
      });
    } catch {}
  };

  //----------------------

  triggerGtagEvent = ({
    event,
    eventArgs,
    suffixUnderscore = true,
  }: EventProps) => {
    try {
      let eventName: string = event;
      if (suffixUnderscore) {
        eventName = `${eventName}_`;
      }
      window.gtag("event", eventName, eventArgs);

      if (IS_STAGING_ENV) {
        console.log("DEBUG:GTAG", {
          event: {
            name: eventName,
            eventArgs,
          },
        });
      }
    } catch {}
  };

  triggerEvent = (data: EventProps) => {
    this.triggerGtagEvent(data);
    this.triggerAmplitudeEvent(data);
  };

  //----------------------

  triggerOfferFormEvent = (
    event: OfferEvents,
    eventArgs?: OfferFormEventArgs
  ) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };

  triggerShareOfferEvent = (
    event: ShareOfferEvents,
    eventArgs?: ShareOfferEventArgs
  ) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };

  triggerAccpetOfferEvent = (eventArgs: { contractId: string }) => {
    this.triggerEvent({
      event: "accept_offer",
      eventArgs,
    });
  };

  triggerRejectOfferEvent = (
    event: RejectOfferEvents,
    eventArgs: { contractId: string }
  ) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };

  triggerFundOfferEvent = ({
    contractId,
    value,
  }: {
    contractId: string;
    value: number;
  }) => {
    this.triggerEvent({
      event: "purchase",
      eventArgs: {
        transaction_id: `OFFER_${contractId}`,
        value,
        currency: "USD",
      },
    });
  };

  //----------------------

  triggerNotifyEvent = (event: NotifyEvents, eventArgs?: NotifyEventsArgs) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };

  triggerFeedbackEvent = (
    event: FeedbackEvents,
    eventArgs?: FeedbackEventsArgs
  ) => {
    this.triggerEvent({
      event,
      eventArgs,
    });
  };
}
