import { IS_STAGING_ENV, PAYPIPE_SITE_URL } from "./app";

export const SITE_PATHS = {
  HOME_PAGE: "/",
  CONTRACTS_PAGE: "/contracts",
  CONTRACTS_ONBOARD_PAGE: "/contracts/onboard",
  CREATE_CONTRACT_PAGE: "/contracts/new",
  OFFERS_PAGE: "/offers",
  CHAT_PAGE: "/chats",
  EDIT_PROFILE_PAGE: "/profile/edit",
  PROFILE_PAGE: "/profile",
  USER_SERVICE_DETAILS_PAGE: "/profile/services/:service_id",
  USER_PORTFOLIO_DETAILS_PAGE: "/profile/portfolios/:porfolio_id",
  SETTINGS_PAGE: "/settings",
  CONTRACT_REJECT_PAGE: "/contracts/reject",
  CONTRACT_MANAGE_PAGE: "/contracts/manage",
  AUTH_REDIRECT_PAGE: "/auth",
  ACTIONS_PAGE: "/actions",
  NOTIFICATIONS_PAGE: "/notifications",
  TRANSACTION_PAGE: "/transactions",
  SUBSCRIPTION_PAGE: "/subscriptions",
  MANAGE_SUBSCRIPTION_PAGE: "/subscriptions/manage",
  CANCEL_SUBSCRIPTION_PAGE: "/subscriptions/cancel",
  APP_RATING_PAGE: "/rate",
  DEV_PAGE: "/dev",
  PAYOUT_ONBOARDING_PAGE: "/payout-onboarding",
  PREFERENCES_SETTING_PAGE: "/preferences",
  TERMS_CONDITIONS_PAGE: "/terms-of-service",
  POLICY_PAGE: "/privacy",
  PAYPIPE_ID_PAGE: "/paypipe-id",
  HEADLESS_REGISTER_PAGE_OLD: "/register_",
  HEADLESS_LOGIN_PAGE_OLD: "/login_",
  HEADLESS_REGISTER_PAGE: "/register",
  HEADLESS_LOGIN_PAGE: "/login",
  CLAIM_ID_PAGE: "/claim",
  PAYPIPE_ID_404_PAGE: "/id-not-found",
  CONTACT_PAGE: "/contact",
};

const PAYPIPE_APP_URL =
  process.env.REACT_APP_PAYPIPE_APP_URL! ||
  (IS_STAGING_ENV
    ? "https://paypipe-staging.cspaces.com"
    : "https://app.paypipe.io");

export const EXTERNAL_PATHS = {
  PAYPIPE_SITE: "https://paypipe.io/",
  PAYPIPE_WELCOME_PAGE: `${PAYPIPE_SITE_URL}/welcome`,
  PAYPIPE_ID_SITE:
    process.env.REACT_APP_PAYPIPE_ME_SITE_URL! ||
    (IS_STAGING_ENV ? "https://staging.paypipe.me" : "https://paypipe.me"),
  PAYPIPE_ID_LANDING_PAGE:
    process.env.REACT_APP_PAYPIPE_ME_LANDING_PAGE_URL! ||
    (IS_STAGING_ENV
      ? "https://ordinary-moments-014937.framer.app"
      : "https://ordinary-moments-014937.framer.app"),
  PAYPIPE_APP: PAYPIPE_APP_URL,
};
