import { DeviceUUID } from "device-uuid";

export function generateDeviceId() {
  const du = new DeviceUUID().parse();

  const {
    platform,
    os,
    resolution,
    isTouchScreen,
    language,
    colorDepth,
    cpuCores,
    source: userAgent,
  } = du;
  const [width, height] = resolution;
  const minDimention = Math.min(width, height);
  const maxDimention = Math.max(width, height);

  const idParts = [platform, os, cpuCores, language];
  idParts.push(`${minDimention}x${maxDimention}|${colorDepth}`);
  idParts.push(isTouchScreen ? "touch" : "no_touch");

  const id = idParts
    .map(String)
    .map((item) => item.toLocaleLowerCase().split(" ").join("-"))
    .join("|");

  return { id, userAgent };
}
