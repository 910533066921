import styled from "@emotion/styled";

import Button from "@/components/input/Button";
import { Body, Label } from "@/components/Typography";
import { squareSizing } from "@/utils/css";
import Icon from "@/components/misc/Icon";
import { screenLargerThan, screenSmallerThan } from "@/styles";

export const StyledToastRegion = styled.div`
  position: fixed;
  left: 0;
  display: flex;
  gap: 0.75rem;
  padding: 1rem;
  border-radius: 4px;
  z-index: 10000;

  ${screenSmallerThan.tablet} {
    top: 0;
    flex-direction: column-reverse;
    width: 100%;
    background: rgba(0, 0, 0, 0.02);
  }

  ${screenLargerThan.tablet} {
    bottom: 0;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const StyledToast = styled.div`
  background: #fff;
  padding: 0.75rem;
  padding-top: 4rem !important;
  border-radius: 1rem;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow:
    0px 0.6729323267936707px 2.018796920776367px 0px #00000005,
    0px 4.037593841552734px 6.729323387145996px 0px #b1b1b114;

  &[data-animation="entering"] {
    animation: enter 280ms ease-out forwards;
  }

  &[data-animation="queued"] {
    animation: fade-in 280ms ease-out forwards;
  }

  &[data-animation="exiting"] {
    animation: exit 280ms ease-out forwards;
  }

  ${screenSmallerThan.tablet} {
    @keyframes enter {
      from {
        transform: translateY(-8px);
        opacity: 0;
      }

      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes exit {
      from {
        transform: translateY(0);
        opacity: 1;
      }

      to {
        transform: translateY(8px);
        opacity: 0;
      }
    }
  }

  ${screenLargerThan.tablet} {
    padding: 1rem;
    width: 20rem;

    @keyframes enter {
      from {
        transform: translateY(8px);
        opacity: 0;
      }

      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes exit {
      from {
        transform: translateY(0);
        opacity: 1;
      }

      to {
        transform: translateY(-8px);
        opacity: 0;
      }
    }
  }
`;

export const StyledCloseButton = styled(Button)`
  ${squareSizing("1.5rem")};
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(245, 245, 247, 1) !important;
  border-radius: 10rem;
  padding: 0;
  overflow: hidden;

  &:focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px slateblue,
      0 0 0 4px white;
  }

  &:active {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const StyledTitle = styled(Label)`
  color: rgba(2, 17, 60, 1);
`;

export const StyledDescription = styled(Body)`
  color: rgba(78, 88, 118, 1);
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 1rem;
  left: 0.75rem;
`;
