// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCnDq_A1rRowuQZzpZOlL5fdumFUhyW6TA",
  authDomain: "paypipe-36176.firebaseapp.com",
  projectId: "paypipe-36176",
  storageBucket: "paypipe-36176.appspot.com",
  messagingSenderId: "75768123493",
  appId: "1:75768123493:web:2fb45c8448a0488572368c",
  measurementId: "G-6XEYSSHLD8",
  vapidKey:
    "BDtuJukNHM6mslaocOwIhCk5n4md4L1t72wU5q455lAdenbYadu5PLR0Fg0wr1_pUCpsd6Bb3QNxbx74AVbB9FU",
};
