import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Location } from "../Routing.types";

function usePathname() {
  const location = useLocation();

  const value = useMemo(
    () =>
      ({
        ...location,
        url: `${location.pathname}${location.search}${location.hash}`,
      } as Location),
    [location]
  );

  return value;
}

export default usePathname;
