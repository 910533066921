import { Color, Size } from "./Icon.types";

export const ICON_SIZE_CONFIG: Record<
  Size,
  {
    size: string;
  }
> = {
  xxs: {
    size: "0.9rem",
  },
  xs: {
    size: "1rem",
  },
  sm: {
    size: "1.25rem",
  },
  md: {
    size: "1.5rem",
  },
  lg: {
    size: "2rem",
  },
};

export const DEFAULT_SIZE: Size = "md";

export const COLOR_CONFIG: Partial<
  Record<
    Color,
    {
      style: React.CSSProperties;
    }
  >
> = {
  primary: {
    style: {
      filter: "var(--filter-primary-500)",
    },
  },

  black: {
    style: {
      filter: "var(--filter-black)",
    },
  },

  white: {
    style: {
      filter: "var(--filter-white)",
    },
  },

  gray: {
    style: {
      filter: "var(--filter-gray)",
    },
  },
};
