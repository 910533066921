import { Route, Routes } from "react-router-dom";

import PaypipeIdPage from "@/features/pages/user/PublicPaypipeIdPage";
import LandingPage from "./features/pages/user/LandingPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/:id" element={<PaypipeIdPage />} />
      <Route path="/" element={<LandingPage />} />
    </Routes>
  );
}

export default AppRoutes;
