import { OverlayTriggerState } from "react-stately";

import { IModalProps } from "@/components/misc/Modal";

export type DrawerState = OverlayTriggerState;

export enum DrawerPostion {
  fullScreen = "fullScreen",
  midScreen = "midScreen",
  closed = "closed",
}

export interface IDrawerProps {
  state: DrawerState;
  drawerMidHeight?: string;
  className?: string;
  children?: React.ReactNode;
  canDrag?: boolean;
  headerProps?: {
    children?: React.ReactNode;
    className?: string;
  };
  useContentHeightAsMidHeight?: boolean;
  modalProps?: Omit<IModalProps, "state">;
  useModalOnDesktop?: boolean;
  includeTabBarHeight?: boolean;
  zIndex?: number;
  fullscreenByDefault?: boolean;
}
