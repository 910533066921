import useSWR from "swr";
import { useCallback, useEffect, useState } from "react";
import lodashGet from "lodash/get";

import { userService } from "@/config/services";
import {
  selectIsStripeOnboarded,
  selectLoadingIsStripeOnboarded,
  useAppSelector,
} from "@/services/Store";

import { OnboardStripeApiParams } from "../UserService.types";
import useUpdateProfile from "./useUpdateProfile";

function useOnboardStripe() {
  const [loadedOnboardingState, setLoadedOnboardingState] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);

  const isOnboarded = useAppSelector(selectIsStripeOnboarded);
  const isLoadingOnboardedState = useAppSelector(
    selectLoadingIsStripeOnboarded
  );

  const { profileData, update, isProfileDataLoading } = useUpdateProfile();

  const [updatingCanOnboardState, setUpdatingCanOnboardState] = useState(false);

  //------------------------

  const handleLoadOnboardingState = useCallback(
    () =>
      userService.isStripeOnboarded().finally(() => {
        setLoadedOnboardingState(true);
      }),
    []
  );

  const { data, isLoading } = useSWR(
    "/payment/check-onboard-status",
    handleLoadOnboardingState,
    {
      errorRetryInterval: 10000,
      errorRetryCount: 1,
      dedupingInterval: 20000,
    }
  );

  const loadedStateOnce = data !== undefined;
  const isReady =
    !isProfileDataLoading && (loadedOnboardingState || loadedStateOnce);

  const canOnboardState = lodashGet(profileData, "misc.canOnboard");
  const canOnboard = canOnboardState && !isOnboarded;

  const onboard = useCallback(
    (params: OnboardStripeApiParams) => {
      if (isOnboarding) {
        return;
      }

      setIsOnboarding(true);

      return userService
        .onboardStripe(params)
        .then((res) => {
          const stripeOnboardUrl = lodashGet(res, "data.data.url", "");
          if (stripeOnboardUrl) {
            window.location.href = stripeOnboardUrl;
          } else {
            setIsOnboarding(false);
          }
        })
        .catch(() => {
          setIsOnboarding(false);
        });
    },
    [isOnboarding]
  );

  const updateCanOnboardState = useCallback(
    (state: boolean) => {
      if (updatingCanOnboardState || isProfileDataLoading) {
        return;
      }

      const currentState = lodashGet(profileData, "misc.canOnboard");

      if (currentState === state) {
        return Promise.resolve();
      }

      const updatedProfileData = JSON.parse(JSON.stringify(profileData));
      updatedProfileData.misc.canOnboard = state;

      setUpdatingCanOnboardState(true);
      return update(updatedProfileData, { notify: false }).finally(() => {
        setUpdatingCanOnboardState(false);
      });
    },
    [profileData, update, updatingCanOnboardState, isProfileDataLoading]
  );

  //------------------------

  useEffect(() => {
    if (
      isReady ||
      isOnboarded ||
      isLoadingOnboardedState ||
      loadedStateOnce ||
      isLoading
    ) {
      return;
    }

    handleLoadOnboardingState();
  }, [
    isReady,
    isOnboarded,
    isLoadingOnboardedState,
    handleLoadOnboardingState,
    loadedStateOnce,
    isLoading,
  ]);

  //------------------------

  return {
    isOnboarded,
    isLoadingOnboardedState,
    isReady,
    onboard,
    isOnboarding,
    updateCanOnboardState,
    canOnboard,
  };
}

export default useOnboardStripe;
