import React, { ReactNode } from "react";
import styled from "@emotion/styled";

import { screenLargerThan } from "@/styles";

const StyledHeading = styled.div`
  font-size: 1.875rem;
  font-weight: 600;
  word-break: break-word;

  ${screenLargerThan.widescreen} {
    font-size: 2.75rem;
  }
`;

const PageHeading: React.FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <StyledHeading className={className}>{children}</StyledHeading>;
};

export default PageHeading;
