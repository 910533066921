"use client";

import React from "react";
// import { Provider as StoreProvider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import { ResponsiveProvider } from "@/styles";
import { ThemeManagerProvider } from "@/styles/ThemeManager";
import { PwaProvider } from "@/services/Pwa";
import { ToastProvider } from "@/components/misc/Toast";
// import { ContractsServiceProvider } from "@/services/ContractsService";
// import { AuthProvider } from "@/services/Authentication";
// import { store } from "@/services/Store";

const Providers: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <HelmetProvider>
      <PwaProvider>
        <ResponsiveProvider>
          <ThemeManagerProvider>
            <ToastProvider>{children}</ToastProvider>
          </ThemeManagerProvider>
        </ResponsiveProvider>
      </PwaProvider>
    </HelmetProvider>
  );
};

export default Providers;
