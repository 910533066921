import { useEffect } from "react";
import { useOverlayTriggerState } from "react-stately";

import { useHash } from "@/services/Routing";

import { ModalStateProps } from "./Modal.types";

export const useModalState = (props: ModalStateProps = {}) => {
  const { urlHash, onOpenChange, defaultOpen } = props;
  const { hash, addHash, removehash } = useHash();

  const useUrlHash = !!urlHash;

  const modalState = useOverlayTriggerState({
    ...props,
    defaultOpen: defaultOpen || (useUrlHash ? hash === urlHash : defaultOpen),
    onOpenChange(isOpen) {
      if (onOpenChange) {
        onOpenChange(isOpen);
      }

      if (urlHash) {
        if (isOpen) {
          addHash(urlHash, false);
        } else {
          if (hash === urlHash) {
            removehash();
          }
        }
      }
    },
  });

  //----------------------------

  useEffect(() => {
    if (!useUrlHash) {
      return;
    }

    if (hash === urlHash) {
      modalState.open();
    } else {
      modalState.close();
    }
  }, [useUrlHash, hash, modalState, urlHash]);

  //----------------------------

  return modalState;
};
