import useSWR from "swr";

import { contractService } from "@/config/services";

function useFileSignedUrl(fileKey: string) {
  const { error, isLoading, data } = useSWR(
    fileKey ? `/media/getPresignedUrl/${fileKey}` : null,
    () =>
      contractService
        .getFileSignedUrl(fileKey)
        .then((res) => res.data.data.url),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const isUpdating = !!data && isLoading;
  const notFound = !data && !isLoading;

  return {
    url: data,
    error,
    isLoading,
    isUpdating,
    notFound,
  };
}

export default useFileSignedUrl;
