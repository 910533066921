import { useCallback, useState } from "react";

import { useNavigate } from "@/services/Routing";
import { getClaimIdUrl } from "@/services/UserService";

function useClaimId() {
  const [id, setId] = useState("");
  const { navigate } = useNavigate();

  const canClaim = true;

  const claim = useCallback(() => {
    if (!canClaim) {
      return;
    }

    navigate(getClaimIdUrl(id));
  }, [id, navigate, canClaim]);

  return {
    id,
    setId,
    claim,
    canClaim,
  };
}

export default useClaimId;
