import { pushNotifications } from "@/config/services";

import { useCallback, useEffect, useState } from "react";

const usePushNotificationPermission = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    pushNotifications.checkIsEnabled().then(setIsEnabled);
    pushNotifications.isSupported().then(setIsSupported);
  }, []);

  const prompt = useCallback(() => {
    pushNotifications
      .promptForPermission()
      .then((granted) => {
        setIsEnabled(granted);
      })
      .catch(() => {
        setIsEnabled(false);
      });
  }, []);

  return { isEnabled, prompt, isSupported };
};

export default usePushNotificationPermission;
