import styled from "@emotion/styled/macro";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";

export const StyledContentEditable = styled(ContentEditable)`
  width: 100%;
  height: 100%;
  outline: none;
`;

export const StyledContentContainer = styled.div`
  flex: 1;
  position: relative;
`;

export const StyledPlaceholder = styled.div`
  position: absolute;
  z-index: 1;
  top: 0.6rem;
  left: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--clr-icon-text-200, #818898);
  font-weight: 400;
  border-bottom: 1.5px solid var(--border-color);
`;

export const StyledContainer = styled.div<{
  $showFullContent: boolean;
  $unstyled: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${({ $unstyled }) =>
    $unstyled ? `` : 
     `
        background: var(--clr-neutral-solid-0, #f8f9fb);

        ${StyledContentContainer} {
            background: var(--clr-neutral-solid-0, #f8f9fb);
        }

        ${StyledContentEditable} {
            padding: 0.75rem;
        }
     `
    };

  ${({ $showFullContent }) =>
    $showFullContent
      ? ``
      : `height: 100%;
        overflow: hidden;`};

  ${StyledContentEditable} {
    ${({ $showFullContent }) =>
      $showFullContent
        ? ``
        : ` position: absolute;
  top: 0;
  left: 0;`};
  }

  & > .toolbar {
    order: 2;
    border: none;
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 2;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    width: 100%;
  }

  & > .editor {
    ${({ $showFullContent }) =>
      $showFullContent
        ? ``
        : `
    overflow: auto;`};

    p {
      margin: 0;
    }

    a {
      color: var(--clr-primary-100, #5f57ff);
    }

    ul {
      list-style: disc;
      margin-left: 2rem;
    }
  }

  .PlaygroundEditorTheme {
    &__textUnderline,
    &__textStrikethrough {
      text-decoration: underline;
    }
  }
`;
