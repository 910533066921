import styled from "@emotion/styled";

import { squareSizing } from "@/utils/css";

export const StyledContainer = styled.div`
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
  background: var(--clr-background-white, #fff);

  & > *:first-child {
    border-top-left-radius: inherit;
  }

  & > *:last-child {
    border-top-right-radius: inherit;
  }
`;

export const StyledButton = styled.button<{ $active?: boolean }>`
  ${squareSizing("52px")};
  width: 52px;
  margin: 0;
  border: none;
  background: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ $active }) =>
    $active
      ? `
            box-shadow: none;
            background: var(--clr-primary-25, #e5e4fb);
            border: 1px solid rgba(0, 0, 0, 0.04);
            border-radius: 0;
        `
      : ` 
          &:hover {
            box-shadow: none;
            background: rgba(0, 0, 0, 0.04);
          }
        `}
`;
