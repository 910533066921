import React from "react";

import { StyledCircularLoader, StyledLoader } from "./ShimmerLoader.styles";
import {
  IShimmerLoaderCircularProps,
  IShimmerLoaderProps,
} from "./ShimmerLoader.types";

const ShimmerLoader: React.FC<IShimmerLoaderProps> = ({
  style,
  className,
  children,
}) => {
  return (
    <StyledLoader style={style} className={className}>
      {children}
    </StyledLoader>
  );
};

export const Circular: React.FC<IShimmerLoaderCircularProps> = ({
  size = "4rem",
  style,
  className,
  children,
}) => {
  return (
    <StyledCircularLoader $size={size} style={style} className={className}>
      {children}
    </StyledCircularLoader>
  );
};

const api = {
  Default: ShimmerLoader,
  Circular,
};

export default api;
