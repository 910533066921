import { createContext, useCallback, useContext, useRef } from "react";
import { useToastState } from "@react-stately/toast";
import { useToastRegion } from "@react-aria/toast";

import {
  IToastContext,
  IToastProviderProps,
  ToastDetails,
} from "./Toast.types";
import Toast from "./Toast";
import { StyledToastRegion } from "./Toast.styles";

const ToastContext = createContext({} as IToastContext);

export const useToast = () => useContext(ToastContext);

export const ToastProvider: React.FC<IToastProviderProps> = ({ children }) => {
  const state = useToastState<ToastDetails>({
    maxVisibleToasts: 5,
    hasExitAnimation: true,
  });
  const toastRegionRef = useRef(null);
  const { regionProps } = useToastRegion(
    { "aria-label": "Quick Notifications" },
    state,
    toastRegionRef
  );

  //-----------------------

  const createToast = useCallback(
    (toast: ToastDetails) => {
      const { priority, onClose, timeoutInMilliseconds: timeout } = toast;
      const toastId = state.add(toast, { priority, onClose, timeout });

      return toastId;
    },
    [state]
  );

  const closeToast = useCallback(
    (toastId: string) => {
      state.close(toastId);
    },
    [state]
  );

  //-----------------------

  return (
    <ToastContext.Provider value={{ createToast, closeToast }}>
      {children}
      <StyledToastRegion
        {...regionProps}
        ref={toastRegionRef}
        style={
          state?.visibleToasts?.length
            ? {}
            : { pointerEvents: "none", opacity: "0" }
        }
      >
        {state.visibleToasts.map((toast) => (
          <Toast key={toast.key} toast={toast} state={state} />
        ))}
      </StyledToastRegion>
    </ToastContext.Provider>
  );
};
