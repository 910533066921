"use client";

import styled from "@emotion/styled";

import { getViewportBottom, squareSizing } from "@/utils/css";

import { DrawerPostion } from "./Drawer.types";
import {
  getViewPortHeightCssString,
  getViewPortWidthCssString,
} from "@/styles";

export const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: ${getViewPortWidthCssString()};
  height: ${getViewPortHeightCssString()};
  background: rgba(0, 0, 0, 0.5);
  margin: 0 !important;
  transition: background 300ms ease;
  animation: control-delay 500ms ease-out forwards;

  @keyframes control-delay {
    0% {
      pointer-events: none;
    }
    100% {
      pointer-events: all;
    }
  }
`;

export const StyledDrawer = styled.div<{
  $isDragging: boolean;
  $position: DrawerPostion;
  $initialHeight?: string;
}>`
  position: fixed;
  bottom: 0;
  bottom: ${getViewportBottom()};
  left: 0;
  z-index: 8;
  display: flex;
  flex-direction: column;
  ${({ $position }) =>
    $position !== DrawerPostion.closed ? "" : "padding-bottom: 86px;"};
  border: 1px solid var(--clr-border-ukwn-2, #efefef);
  width: 100vw;
  height: ${({ $initialHeight }) => $initialHeight || "20vh"};
  background: var(--clr-background-primary, #fff);
  border-top-left-radius: ${({ $position, $isDragging }) =>
    $position === DrawerPostion.fullScreen && !$isDragging ? 0 : "2rem"};
  border-top-right-radius: ${({ $position, $isDragging }) =>
    $position === DrawerPostion.fullScreen && !$isDragging ? 0 : "2rem"};
  box-shadow: 0 -2px 40px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: border-radius 120ms ease;
  ${({ $isDragging }) =>
    $isDragging ? `` : `transition: height 190ms ease-out;`};

  animation: fade-in 120ms ease-out forwards;
`;

export const StyledHeader = styled.div`
  position: relative;
  min-height: 1.5rem;
  padding: 1rem;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: var(--clr-text-ukwn-2, #1a1d1f);
`;

export const StyledHandle = styled.div`
  ${squareSizing("40px")};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 4px;
    background: var(--clr-background-ukwn-6, #e7e7e7);
    border-radius: 10rem;
  }
`;

export const StyledContentSection = styled.div<{ $isDragging: boolean }>`
  flex: 1;
  overflow: ${({ $isDragging }) => ($isDragging ? "hidden" : "auto")};

  & > div {
    animation-delay: 120ms;
  }
`;
