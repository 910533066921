import { IApiService } from "@/services/Api";

class Authentication {
  _apiService: IApiService;

  constructor(_apiService: IApiService) {
    this._apiService = _apiService;
  }
}

export default Authentication;
