import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import lodashGet from "lodash/get";

import { appFeatures, appSettings } from "@/config/services";

import {
  IThemeManagerContext,
  Theme,
  ThemeDetails,
} from "./ThemeManager.types";
import { getDefaultTheme, setThemeVars } from "./ThemeManager.utils";
import THEMES from "./themes";

const initialValues = {} as IThemeManagerContext;

export const ThemeManagerContext =
  createContext<IThemeManagerContext>(initialValues);

export const useThemeManager = () =>
  useContext<IThemeManagerContext>(ThemeManagerContext);

export const ThemeManagerProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [activeTheme, setActiveTheme] = useState(() => {
    const theme = getDefaultTheme();
    setThemeVars(theme);

    return theme;
  });

  const darkModeEnabled = activeTheme === Theme.Dark;

  const setTheme = useCallback((name: Theme) => {
    const theme = lodashGet(THEMES, name) as ThemeDetails;
    if (theme) {
      setActiveTheme(name);
      setThemeVars(name);
    }
  }, []);

  const toggleDarkMode = useCallback(
    (value?: boolean) => {
      let newTheme: Theme = Theme.Light;

      if (value !== undefined) {
        newTheme = value ? Theme.Dark : Theme.Light;
      }

      setTheme(newTheme);
      appSettings.set("THEME", newTheme);
    },
    [setTheme]
  );

  //------------------------------

  useEffect(() => {
    if (!appFeatures.isSupported("CUSTOMIZATION.DARK_MODE")) {
      return;
    }

    const callback = (event: MediaQueryListEvent) => {
      let theme = Theme.Light;

      if (event.matches) {
        theme = Theme.Dark;
      }

      setThemeVars(theme);
    };

    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.addEventListener("change", callback);

    return () => {
      matchMedia.removeEventListener("change", callback);
    };
  }, []);

  //------------------------------

  return (
    <ThemeManagerContext.Provider
      value={{
        activeTheme,
        setActiveTheme: setTheme,
        toggleDarkMode,
        darkModeEnabled,
      }}
    >
      {children}
    </ThemeManagerContext.Provider>
  );
};
