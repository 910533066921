import { screenLargerThan, screenSmallerThan } from "@/styles";
import styled from "@emotion/styled";

export const StyledSmallText = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

export const StyledHeading = styled.div`
  font-size: 2.5rem;
  font-weight: bold;

  ${screenLargerThan.tablet} {
    font-size: 5rem;
  }
`;

export const StyledSubHeading = styled.div`
  font-size: 1rem;
  font-weight: 500;
  max-width: 32ch;

  ${screenSmallerThan.tablet} {
    margin-inline: auto;
  }

  ${screenLargerThan.tablet} {
    font-size: 1.25rem;
  }
`;

export const StyledImage = styled.img`
  flex: 1;
  object-fit: contain;
  overflow: hidden;

  ${screenSmallerThan.tablet} {
    max-width: 18.25rem;
    margin-inline: auto;
  }

  ${screenLargerThan.tablet} {
    width: 100%;
    height: 100%;
  }
`;

export const StyledContainer = styled.div`
  ${screenLargerThan.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    height: 60vh;
  }
`;

export const StyledContentSection = styled.div`
  flex: 1;

  ${screenLargerThan.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
    padding-block: 7.5%;
  }

  ${screenSmallerThan.tablet} {
    padding-block: 10%;

    & > * {
      margin-bottom: 1.5rem;
    }
  }
`;

export const StyledPage = styled.div`
  background: var(--clr-primary-100, #5f57ff);
  color: #fff;
  height: 100%;

  ${screenLargerThan.tablet} {
    display: flex;
    align-items: center;
  }

  ${screenSmallerThan.tablet} {
    text-align: center;
  }
`;
