import { SITE_PATHS } from "@/config/routing";
import {
  ContractCreationFlow,
  ContractCreatorType,
  ContractType,
} from "../ContractsService.types";

export function getContractPageUrl(contractId: string) {
  return `${SITE_PATHS.CONTRACTS_PAGE}?id=${contractId}`;
}

export function getOfferPageUrl(contractId: string) {
  return `${SITE_PATHS.OFFERS_PAGE}?id=${contractId}`;
}

export function getContractRejectPageUrl(contractId: string) {
  return `${SITE_PATHS.CONTRACT_REJECT_PAGE}?id=${contractId}`;
}

export function getContractManagePageUrl(contractId: string) {
  return `${SITE_PATHS.CONTRACTS_PAGE}?id=${contractId}`;
}

export function getContractChatPageUrl(contractId: string) {
  return `${SITE_PATHS.CHAT_PAGE}?id=${contractId}`;
}

export function getContractPaymentOnboardPageUrl(contractId: string) {
  return `${SITE_PATHS.CONTRACTS_ONBOARD_PAGE}?id=${contractId}`;
}

export function getContractCreateUrl(
  config: {
    flow?: ContractCreationFlow;
    creatorType?: ContractCreatorType;
    contractType?: ContractType;
  } = {}
) {
  const { flow, creatorType, contractType } = config;
  const params: Record<string, string> = {};

  if (flow) {
    params.flow = flow;
  }

  if (creatorType) {
    params.creator = creatorType;
  }

  if (contractType) {
    params.type = contractType;
  }

  let queryParams = "";

  if (Object.keys(params).length) {
    queryParams = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }

  return `${SITE_PATHS.CREATE_CONTRACT_PAGE}${
    queryParams ? `?${queryParams}` : ""
  }`;
}
