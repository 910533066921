import { Theme, ThemeDetails } from "../ThemeManager.types";

const THEME: ThemeDetails = {
  id: Theme.Light,
  colors: {
    neutral: {
      solid: {
        0: { hex: "#f8f9fb" },
        25: { hex: "#f6f8fa" },
        50: { hex: "#eceff3" },
        100: { hex: "#dfe1e6" },
        200: { hex: "#c1c7cf" },
        300: { hex: "#a4abb8" },
        400: { hex: "#808897" },
        500: { hex: "#666d80" },
        600: { hex: "#31353f" },
        700: { hex: "#272835" },
        800: { hex: "#1a1b25" },
        900: { hex: "#0d0d12" },
      },
    },

    background: {
      white: { hex: "#fff" },
      primary: { hex: "#fff" },
      0: { hex: "#f8f9fb" },
      25: { hex: "#f6f8fa" },
      50: { hex: "#eceff3" },
      100: { hex: "#dfe1e6" },
      900: { hex: "#0d0d12" },
      "primary-lighter": { hex: "#EBF1FF" },
      "ukwn-1": { hex: "#FAFAFA" },
      "ukwn-2": { hex: "#f5f5f5" },
      "ukwn-3": { hex: "#A19DF1" },
      "ukwn-4": { hex: "#dfe1e7" },
      "ukwn-5": { hex: "#F9A80A" },
      "ukwn-6": { hex: "#e7e7e7" },
      "ukwn-7": { hex: "#f9f9f9" },
      "ukwn-8": { hex: "#fbfbfb" },
      "ukwn-9": { hex: "#000005" },
      "ukwn-10": { hex: "#e5f7ad" },
      "ukwn-11": { hex: "#FFF0F3" },
    },

    primary: {
      0: { hex: "#F0EFFF" },
      25: { hex: "#E5E4FB" },
      50: { hex: "#A19CF0" },
      100: { hex: "#5F57FF" },
      200: { hex: "#312BA0" },
      300: { hex: "#201C6C" },
      light: { hex: "#7E7BC3" },
    },

    icon: {
      text: {
        200: { hex: "#818898" },
      },
      300: { hex: "#a4abb8" },
      400: { hex: "#818898" },
      500: { hex: "#666d80" },
      600: { hex: "#353849" },
      900: { hex: "#0d0d12" },
    },

    text: {
      white: { hex: "#fff" },
      300: { hex: "#a4abb8" },
      400: { hex: "#808897" },
      500: { hex: "#666d80" },
      600: { hex: "#353849" },
      900: { hex: "#0d0d12" },
      "ukwn-1": { hex: "#171717" },
      "ukwn-2": { hex: "#1a1d1f" },
      "ukwn-3": { hex: "#1e75ff" },
      "ukwn-4": { hex: "#242b42" },
      "ukwn-5": { hex: "#7e8ca0" },
      "ukwn-6": { hex: "#1d1e25" },
      "ukwn-7": { hex: "#808d9e" },
      "ukwn-8": { hex: "#0f172a" },
    },

    border: {
      25: { hex: "#f6f8fa" },
      50: { hex: "#eceff3" },
      100: { hex: "#dfe1e6" },
      200: { hex: "#c1c7cf" },
      "ukwn-1": { hex: "#f5f5f5" },
      "ukwn-2": { hex: "#efefef" },
      "ukwn-3": { hex: "#f1f0f3" },
      "ukwn-4": { hex: "#e9e9e9" },
      "ukwn-5": { hex: "#dfe1e7" },
      "ukwn-6": { hex: "#E5E5E5" },
      "ukwn-7": { hex: "#A19DF1" },
      "ukwn-8": { hex: "#f8f8fb" },
      "ukwn-9": { hex: "#f0f5f9" },
      "ukwn-10": { hex: "#ccc" },
      "ukwn-11": { hex: "#f2f2f2" },
      "ukwn-12": { hex: "#f3f3f3" },
      "ukwn-13": { hex: "#000005" },
      "ukwn-14": { hex: "#f4f5f6" },
      "ukwn-15": { hex: "#ecf1f2" },
      "ukwn-16": { hex: "#8fe7be" },
      "ukwn-17": { hex: "#ed8296" },
    },

    ukwn: {
      1: { hex: "#36394a" },
      2: { hex: "#C1C7D0" },
      3: { hex: "#A4ACB9" },
    },

    error: {
      400: { hex: "#DF1C41" },
      500: { hex: "#ea5b5b" },
    },

    success: {
      500: { hex: "#1dbf73" },
    },

    accent: {
      1: { hex: "#E5F7AD" },
      2: { hex: "#D1F0FA" },
      3: { hex: "#FADBE1" },
    },
  },

  filters: {
    primary: {
      500: {
        value: `brightness(0) invert(40%) sepia(87%) saturate(4402%)
  hue-rotate(232deg) brightness(99%) contrast(103%)`,
      },
    },
    white: {
      value: "brightness(0) invert(1)",
    },
    gray: {
      value: `brightness(0) invert(56%) sepia(10%) saturate(498%)
  hue-rotate(184deg) brightness(93%) contrast(87%)`,
    },
    black: {
      value: `brightness(0) invert(0) invert(9%) sepia(62%) saturate(3%)
  hue-rotate(314deg) brightness(93%) contrast(90%)`,
    },
  },
};

export default THEME;
