"use client";

import styled from "@emotion/styled";
import { Variant } from "./TextField.types";

export const StyledContainer = styled.div<{
  $hasValue: boolean;
  $hasLabel: boolean;
  $hasError: boolean;
  $useFloatingLabel: boolean;
  $variant: Variant;
}>`
  --color-error: var(--clr-error-500, #ea5b5b);
  --color: var(--clr-neutral-solid-500, #525866);
  --label-top: 1rem;
  --label-top-focus: -0.5rem;
  --label-left: 1rem;
  position: relative;
  display: flex;
  align-items: center;

  outline: none;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.75rem;
  color: var(--color);
  outline: none;
  overflow: hidden;

  ${({ $variant, $hasError }) =>
    $variant === "background"
      ? `   
          gap: 0.5rem;
          padding: 0 0.75rem;
          background: ${
            $hasError
              ? "var(--clr-background-ukwn-11, #FFF0F3)"
              : "var(--clr-background-25,  #f6f8fa)"
          };
          border: 1.5px solid ${
            $hasError
              ? "var(--color-error)"
              : "var(--clr-border-ukwn-3, #f1f0f3)"
          };

          & > *:first-of-type:not(input):not(textarea):not(label) {
            margin-inline-start: 0.25rem;
          }
        
          & > *:last-child:not(input):not(textarea):not(label) {
            margin-inline-end: 0.25rem;
          }
        `
      : $variant === "outline"
      ? ` 
          gap: 1rem;

          & > input , & > textarea {
            border-bottom: 1.5px solid ${
              $hasError
                ? "var(--color-error)"
                : "var(--clr-border-ukwn-5, #dfe1e7)"
            } !important;
          }
      `
      : ""}

  ${({ $useFloatingLabel, $hasError }) =>
    $useFloatingLabel
      ? `
        & > input + label,
        & > textarea + label {
          position: absolute;
          top: var(--label-top);
          left: var(--label-left);
          color: ${
            $hasError
              ? "var(--clr-error-500,  #EA5B5B)"
              : "var(--clr-icon-text-200, #818898)"
          };
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.25rem;
          pointer-events: none;
          transition: all 240ms ease;
        }
    `
      : ""}


&:not(:focus-within) {
    ${({ $hasValue }) =>
      $hasValue
        ? ""
        : `
        & > input + label {
          top: 50%;
          transform: translateY(-50%);
        }
    `}
  }

  ${({ $hasValue, $hasLabel, $hasError, $useFloatingLabel }) => {
    if (!$useFloatingLabel) return "";

    const FOCUSED_INPUT_STYLES = $hasLabel
      ? `
      margin-top: 1.25rem;
    `
      : "";

    const FOCUSED_LABEL_STYLES = `
      color:  ${
        $hasError
          ? "var(--clr-error-500,  #EA5B5B)"
          : "var(--clr-ukwn-3,  #A4ACB9)"
      } !important;
      font-size: 0.75rem !important;
      line-height: 1rem !important;
      font-weight: 400 !important;
      transform: translateY(var(--label-top-focus));
    `;

    return $hasValue
      ? `
          input,
          textarea {
            ${FOCUSED_INPUT_STYLES}
          }

          & > input:not([type="search"]) + label , & > textarea + label {
            ${FOCUSED_LABEL_STYLES}
          }
        `
      : ` 
          &:focus-within > input,
          &:focus-within > textarea,
          & > input:not(:placeholder-shown),
          & > textarea:not(:placeholder-shown) {
            ${FOCUSED_INPUT_STYLES}
          }

          &:focus-within > label,
          & > input:not(:placeholder-shown) + label,
          & > textarea:not(:placeholder-shown) + label {
            ${FOCUSED_LABEL_STYLES}
          }
        `;
  }}
`;

export const StyledInputContainer = styled(StyledContainer)<{
  $variant: Variant;
}>`
  min-height: ${({ $variant }) =>
    $variant === "background" ? "56px" : "44px"};
  align-items: center;
`;

export const StyledTextAreaContainer = styled(StyledContainer)`
  height: 10rem;
  align-items: flex-start;
`;

export const StyledInput = styled.input<{
  $hasError: boolean;
  $useFloatingLabel: boolean;
  $variant: Variant;
}>`
  position: relative;
  border: none !important;
  outline: none !important;
  background: transparent !important;
  flex: 1;
  max-width: -webkit-fill-available;
  text-align: inherit;
  overflow: hidden;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;

  ${({ $variant }) =>
    $variant === "background"
      ? `   
        padding: 0.75rem 0.25rem;
        `
      : $variant === "outline"
      ? ` 
        padding-block: 0.75rem;
      `
      : ""}

  &,
  &:-internal-autofill-selected,
  &::autofill,
  &:-webkit-autofill {
    font-size: inherit;
    font-weight: 500;
    color: inherit;
  }

  &::placeholder {
    color: var(--clr-text-500, #666d80);
  }

  ${({ $useFloatingLabel }) =>
    $useFloatingLabel
      ? `
    &::placeholder {
      opacity: 0;
    }
  `
      : ""}

  &:-internal-autofill-selected,
  &::autofill,
  &:-webkit-autofill {
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background: transparent !important;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-buntton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

export const StyledTextArea = styled.textarea<{
  $hasError: boolean;
  $useFloatingLabel: boolean;
}>`
  border: none !important;
  outline: none !important;
  background: transparent !important;
  flex: 1;
  max-width: -webkit-fill-available;
  padding: 1rem 0.25rem;
  text-align: inherit;
  min-height: 100%;
  resize: none;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;

  ${({ $useFloatingLabel }) =>
    $useFloatingLabel
      ? `
    &::placeholder {
      opacity: 0;
    }
  `
      : ""}

  &:-internal-autofill-selected,
  &::autofill,
  &:-webkit-autofill {
    font-size: 1.125rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: inherit;
    background: transparent !important;
  }

  &::placeholder {
    color: var(--clr-text-500, #666d80);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-buntton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;
