import { Theme } from "../ThemeManager.types";
import DEFAULT_THEME from "./default";
import DEFAULT_DARK_THEME from "./default_dark";

const THEMES = {
  [Theme.Light]: DEFAULT_THEME,
  [Theme.Dark]: DEFAULT_DARK_THEME,
};

export default THEMES;
