import styled from "@emotion/styled/macro";

import {
  BREAKPOINT_NAMES,
  screenLargerThan,
  screenSmallerThan,
  useResponsive,
} from "@/styles";

import VerticalSlider from "./VerticalCards";
import UserCard from "./UserCard";

const BREAKPOINT = "tablet" satisfies BREAKPOINT_NAMES;

const USERS: {
  image: string;
  name: string;
  profession: string;
  professionIcon: string;
}[] = [
  {
    image: "/assets/pages/landing/users/user-1.jpg",
    name: "Donelle Kaucher",
    profession: "Photographer",
    professionIcon: "/assets/pages/landing/icons/camera.svg",
  },
  {
    image: "/assets/pages/landing/users/user-2.jpg",
    name: "Chauncey Spolar",
    profession: "Graphics Designer",
    professionIcon: "/assets/pages/landing/icons/graphics.svg",
  },
  {
    image: "/assets/pages/landing/users/user-3.jpg",
    name: "Raj Shankar",
    profession: "Software Engineer",
    professionIcon: "/assets/pages/landing/icons/code.svg",
  },
  {
    image: "/assets/pages/landing/users/user-4.jpg",
    name: "Torre Hayball",
    profession: "Video Editor",
    professionIcon: "/assets/pages/landing/icons/video_editor.svg",
  },
  {
    image: "/assets/pages/landing/users/user-5.jpg",
    name: "Junina Widgeon",
    profession: "Electrician",
    professionIcon: "/assets/pages/landing/icons/plug.svg",
  },
  {
    image: "/assets/pages/landing/users/user-6.jpg",
    name: "Jany Klena",
    profession: "Architect",
    professionIcon: "/assets/pages/landing/icons/building.svg",
  },
  {
    image: "/assets/pages/landing/users/user-7.jpg",
    name: "Donelle Kaucher",
    profession: "Photographer",
    professionIcon: "/assets/pages/landing/icons/camera.svg",
  },
  {
    image: "/assets/pages/landing/users/user-8.jpg",
    name: "Raj Shankar",
    profession: "Graphics Designer",
    professionIcon: "/assets/pages/landing/icons/graphics.svg",
  },
];

//------------------------

const StyledContainer = styled.div`
  ${screenSmallerThan[BREAKPOINT]} {
    min-height: 50vh;
    width: 100%;
  }

  ${screenLargerThan[BREAKPOINT]} {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }
`;

const StyledInnerContainer = styled.div`
  ${screenLargerThan[BREAKPOINT]} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    position: relative;
    top: -4rem;
    right: -4rem;
    max-width: 48rem;
    height: 200%;
    width: 50vw;
    transform: rotate(8.5deg);

    & > * {
      overflow: hidden;
    }

    & > *:nth-child(even) {
      position: relative;
      top: -6rem;
    }

    .card {
      margin-top: 2rem;
    }
  }

  ${screenSmallerThan[BREAKPOINT]} {
    & > *:not(:first-child) {
      margin-top: 1rem;
    }

    .card {
      margin-right: 1rem;
    }
  }
`;

function shiftArrayToRight(arr: any[], places = 0) {
  arr = [...arr];

  for (let i = 0; i < places; i++) {
    arr.unshift(arr.pop());
  }

  return arr;
}

const LIST_1 = USERS;
const LIST_2 = shiftArrayToRight(USERS, 3);
const LIST_3 = shiftArrayToRight(USERS, 5);

const UserCards: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  if (isScreenSmallerThanTablet) {
    return (
      <StyledContainer className={className}>
        <StyledInnerContainer>
          <VerticalSlider>
            {LIST_1.map((user, i) => (
              <UserCard key={i} className="card" {...user} />
            ))}
          </VerticalSlider>
          <VerticalSlider rtl>
            {LIST_2.map((user, i) => (
              <UserCard key={i} className="card" {...user} />
            ))}
          </VerticalSlider>
        </StyledInnerContainer>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer className={className}>
      <StyledInnerContainer>
        <VerticalSlider vertical>
          {LIST_1.map((user, i) => (
            <UserCard key={i} className="card" {...user} />
          ))}
        </VerticalSlider>
        <VerticalSlider rtl vertical>
          {LIST_2.map((user, i) => (
            <UserCard key={i} className="card" {...user} />
          ))}
        </VerticalSlider>
        <VerticalSlider vertical>
          {LIST_3.map((user, i) => (
            <UserCard key={i} className="card" {...user} />
          ))}
        </VerticalSlider>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default UserCards;
