import styled from "@emotion/styled/macro";

import {
  getViewPortHeightCssString,
  screenLargerThan,
  BREAKPOINT_NAMES,
  screenSmallerThan,
  useResponsive,
} from "@/styles";
import Icon from "@/components/misc/Icon";

enum ComparingItems {
  Website = "Website",
  Paypipe = "Paypipe.me",
}

const POINTS: {
  title: React.ReactNode;
  values: Record<ComparingItems, React.ReactNode>;
  mobile?: {
    title?: React.ReactNode;
    values?: Partial<Record<ComparingItems, React.ReactNode>>;
  };
}[] = [
  {
    title: "Launch Time",
    values: {
      [ComparingItems.Paypipe]: "5-10 Minutes",
      [ComparingItems.Website]: "1-4 Weeks",
    },
  },
  {
    title: "Technical Skills",
    values: {
      [ComparingItems.Paypipe]: "No Techincal Skills Required",
      [ComparingItems.Website]: (
        <>
          Required{" "}
          <Icon isSrcRelative src="tick_rounded_2.svg" colorVariant="primary" />
        </>
      ),
    },
    mobile: {
      values: {
        [ComparingItems.Paypipe]: "None",
        [ComparingItems.Website]: (
          <>
            <Icon
              isSrcRelative
              src="tick_rounded_2.svg"
              colorVariant="primary"
            />
          </>
        ),
      },
    },
  },
  {
    title: "Domain Fee",
    values: {
      [ComparingItems.Paypipe]: "None",
      [ComparingItems.Website]: "12.99$/yr",
    },
  },
  {
    title: "Hosting fee",
    values: {
      [ComparingItems.Paypipe]: "None",
      [ComparingItems.Website]: "100$/yr",
    },
  },
  {
    title: "Contact form",
    values: {
      [ComparingItems.Paypipe]: "Integrated Chat",
      [ComparingItems.Website]: "Manually added",
    },
  },
  {
    title: "Built-in Payments",
    values: {
      [ComparingItems.Paypipe]: (
        <Icon isSrcRelative src="tick_rounded_2.svg" colorVariant="white" />
      ),
      [ComparingItems.Website]: <Icon isSrcRelative src="cross_rounded.svg" />,
    },
  },
  {
    title: "Secure Transactions",
    values: {
      [ComparingItems.Paypipe]: (
        <Icon isSrcRelative src="tick_rounded_2.svg" colorVariant="white" />
      ),
      [ComparingItems.Website]: <Icon isSrcRelative src="cross_rounded.svg" />,
    },
  },
  {
    title: "Fraud Protection",
    values: {
      [ComparingItems.Paypipe]: (
        <Icon isSrcRelative src="tick_rounded_2.svg" colorVariant="white" />
      ),
      [ComparingItems.Website]: <Icon isSrcRelative src="cross_rounded.svg" />,
    },
  },
];

const BREAKPOINT = "smallMonitor" satisfies BREAKPOINT_NAMES;

const StyledContainer = styled.div`
  min-height: ${getViewPortHeightCssString()};
  padding-block: 4rem;

  .content {
    text-align: center;
  }
`;

const StyledSection = styled.section`
  color: #000;
  background-color: #f8f8f8;

  ${screenSmallerThan[BREAKPOINT]} {
    padding-top: 3rem;
  }
`;

const StyledHeading = styled.h2`
  margin: 0;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.25em;

  ${screenLargerThan[BREAKPOINT]} {
    font-size: 4.25rem;
  }
`;

const StyledContent = styled.p`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.5px;

  ${screenSmallerThan[BREAKPOINT]} {
    margin-top: 1rem;
  }

  ${screenLargerThan[BREAKPOINT]} {
    margin-top: 1.5em;
    font-weight: 700;
    line-height: 1.75em;
  }
`;

const StyledTable = styled.table`
  --border-radius: 0.5rem;
  width: 100%;
  border-collapse: collapse;

  ${screenLargerThan[BREAKPOINT]} {
    --border-radius: 1.25rem;
  }

  .tbody {
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
    background: #fff;
    margin-top: 0.5rem;
  }

  .tr {
    display: grid;
    position: relative;
    z-index: 3;
    overflow: hidden;
    gap: 0.5rem;
    grid-template-columns: 1.25fr 1fr 1fr;

    &:not(:first-child) {
      border-top: 1.5px solid #f8f8f8;
    }

    & > * {
      flex-grow: 1;
    }

    ${screenLargerThan[BREAKPOINT]} {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &:first-child {
      td.active {
        border-top-left-radius: var(--border-radius);
      }
    }

    &:last-child {
      td.active {
        border-bottom-left-radius: var(--border-radius);
      }
    }
  }

  .td,
  .th {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    min-height: 100%;
    font-size: 0.815rem;
    font-weight: 600;
    line-height: 1.25rem;

    &:not(:first-child) {
      justify-content: center;
    }

    &.active {
      color: #fff;
      background: var(--clr-primary-100, #5f57ff);
    }

    ${screenLargerThan[BREAKPOINT]} {
      font-size: 1.125rem;
    }
  }

  .td {
    min-height: 4.5rem;
    padding: 0.25rem 1rem;

    &:not(:first-child) {
      padding: 0.25rem;
    }

    ${screenLargerThan[BREAKPOINT]} {
      min-height: 4.5rem;
      padding: 1rem 1.5rem;
    }
  }

  .th {
    background: #fff;
    border-radius: 0.5rem;
    padding-block: 0.75rem 1rem;

    &:nth-child(2) {
      position: relative;
      margin-right: 1.5rem;
      right: -0.75rem;

      ${screenLargerThan[BREAKPOINT]} {
        margin-right: 3rem;
        right: -1.5rem;
      }
    }
  }

  ${screenSmallerThan[BREAKPOINT]} {
    margin-top: 2rem;
  }

  ${screenLargerThan[BREAKPOINT]} {
    margin-top: 2rem;
  }
`;

const PricingSection: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  return (
    <StyledSection id="pricing" className={className}>
      <StyledContainer className="container">
        <div className="content">
          <div>
            <StyledHeading>Why Choose Paypipe.me Over A Website?</StyledHeading>
            <StyledContent>
              With Paypipe.me you'll unlock streamlined client management and
              payment peace of mind. Here's how they stack up:
            </StyledContent>
          </div>
        </div>
        <StyledTable>
          <thead className="thead">
            <tr className="tr">
              <th></th>
              <th className="th">{ComparingItems.Website}</th>
              <th className="th active">{ComparingItems.Paypipe}</th>
            </tr>
          </thead>

          <tbody className="tbody">
            {POINTS.map(({ title, values, mobile }) => {
              return (
                <tr className="tr">
                  <td className="td">{title}</td>
                  <td className="td item-td">
                    {isScreenSmallerThanTablet
                      ? mobile?.values?.[ComparingItems.Website] ||
                        values[ComparingItems.Website]
                      : values[ComparingItems.Website]}
                  </td>
                  <td className="td item-td active">
                    {isScreenSmallerThanTablet
                      ? mobile?.values?.[ComparingItems.Paypipe] ||
                        values[ComparingItems.Paypipe]
                      : values[ComparingItems.Paypipe]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </StyledContainer>
    </StyledSection>
  );
};

export default PricingSection;
