import { useResponsive } from "@/styles";
import React from "react";
import Slider, { Settings } from "react-slick";

const VerticalSlider: React.FC<{
  children?: React.ReactNode;
  rtl?: boolean;
  vertical?: boolean;
}> = ({ children, rtl, vertical }) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  const settings: Settings = {
    speed: 16000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: false,
    cssEase: "linear",
    slidesToShow: isScreenSmallerThanTablet ? 2 : 4,
    draggable: isScreenSmallerThanTablet,
    focusOnSelect: isScreenSmallerThanTablet,
    pauseOnFocus: isScreenSmallerThanTablet,
    pauseOnHover: isScreenSmallerThanTablet,
    swipe: isScreenSmallerThanTablet,
    slidesToScroll: 1,
    infinite: true,
    initialSlide: 1,
    arrows: false,
    vertical,
    rtl,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default VerticalSlider;
