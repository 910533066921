import packageJson from "@/../package.json";

export const APP_NAME = "PAYPIPE";
export const APP_ENV = process.env.REACT_APP_ENV! as "staging" | "production";
// export const APP_ENV =  "production" as any;
export const IS_PROD_ENV = APP_ENV === "production";
export const IS_STAGING_ENV = APP_ENV === "staging";
export const PAYPIPE_SITE_URL = process.env.REACT_APP_PAYPIPE_SITE_URL!;

export const APP_VERSION = packageJson.version;
