// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
  isSupported as isSupported_,
} from "firebase/messaging";
import lodashGet from "lodash/get";

import { userService } from "@/config/services";
import { sanitizeProfileData, UserProfile } from "@/services/UserService";
import { IS_STAGING_ENV } from "@/config/app";

import { FIREBASE_CONFIG } from "./PushNotifications.config";
import { generateDeviceId } from "./PushNotifications.utils";
import { Device } from "./PushNotifications.types";

export class PushNotifications {
  _app: FirebaseApp | null = null;
  _analytics: Analytics | null = null;
  _messaging: Messaging | null = null;
  _swRegistration: ServiceWorkerRegistration | null = null;
  _devices: Device[] = [];

  constructor() {
    // Initialize Firebase
    this.isSupported().then(() => {
      this._app = initializeApp(FIREBASE_CONFIG);
      this._analytics = getAnalytics(this._app);
      this._messaging = getMessaging(this._app);
    });
  }

  isSupported = () => {
    return isSupported_();
  };

  init = async () => {
    if (!this._messaging) {
      return;
    }

    const enabled = await this.checkIsEnabled();

    if (!enabled) {
      return;
    }

    const token = await this.getToken();

    if (!token) {
      return;
    }

    this.initializeServiceWorker();

    onMessage(this._messaging, (payload) => {
      this.log("onMessage", payload);
    });

    await this.getDevicesList();

    const { id: currentDeviceId } = generateDeviceId();
    let currentDeviceIndex = this._devices.findIndex(
      ({ id }) => id === currentDeviceId
    );

    if (currentDeviceIndex === -1) {
      this._devices.push({
        id: currentDeviceId,
        token,
        date_added: new Date().toUTCString(),
      });
      currentDeviceIndex = this._devices.length - 1;
      await this.saveDevicesList();
    } else {
      const { token: tokenFromApi } = this._devices[currentDeviceIndex];
      if (tokenFromApi !== token) {
        this._devices[currentDeviceIndex].token = token;
        await this.saveDevicesList();
      }
    }
  };

  getDevicesList = async () => {
    try {
      const userData = await userService.fetchUserInfo();
      const userDetailsStringified = lodashGet(
        userData,
        "data.data.user.details",
        ""
      );
      let profileData = JSON.parse(
        userDetailsStringified || "{}"
      ) as UserProfile;
      profileData = sanitizeProfileData(profileData);
      this._devices = profileData.misc.pushNotificationsDevices;
    } catch {}
  };

  saveDevicesList = async () => {
    try {
      const userData = await userService.fetchUserInfo();
      const userDetailsStringified = lodashGet(
        userData,
        "data.data.user.details",
        ""
      );
      let profileData = JSON.parse(
        userDetailsStringified || "{}"
      ) as UserProfile;
      profileData = sanitizeProfileData(profileData);
      profileData.misc.pushNotificationsDevices = this._devices.filter(
        function (item, pos, self) {
          return self.indexOf(item) === pos;
        }
      );

      await userService.updateUserInfo(
        {
          details: JSON.stringify(profileData),
        },
        { notify: false }
      );
    } catch {}
  };

  promptForPermission = async () => {
    if (!this.isSupported()) {
      return Promise.resolve(false);
    }

    if (Notification.permission === "granted") {
      return Promise.resolve(true);
    } else if (Notification.permission !== "denied") {
      return Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return Promise.resolve(false);
        });
    }

    return Promise.resolve(false);
  };

  checkIsEnabled = async () => {
    try {
      const res = (
        await Promise.all([
          Promise.resolve(Notification.permission === "granted"),
          this.isSupported(),
        ])
      ).every(Boolean);

      return res;
    } catch {
      return false;
    }
  };

  log = (...args: any[]) => {
    if (IS_STAGING_ENV) {
      console.log("DEBUG:NOTIF", ...args);
    }
  };

  initializeWorker = () => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/worker.js")
          .then(
            (registration) => {
              this.log("Worker registration successful", registration.scope);
            },
            (err) => {
              this.log("Worker registration failed", err);
            }
          )
          .catch((err) => {
            this.log("serviceWorker:ERROR", err);
          });
      });
    } else {
      this.log("Service Worker is not supported by browser.");
    }
  };

  initializeServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/service-worker.js").then((swReg) => {
        // swReg.showNotification("This is Message Title");
      });
    } else {
      console.warn("Service workers aren't supported in this browser.");
    }
  };

  getToken = async () => {
    if (!this._messaging) {
      return;
    }

    const currentToken = await getToken(this._messaging, {
      vapidKey: FIREBASE_CONFIG.vapidKey,
    });

    return currentToken;
  };

  showNotification = () => {
    // let notificationOptions = {
    //   body: "Some Notification information",
    //   icon: "<>",
    // };
    // let notif = new Notification("My New Notification", notificationOptions);
    // this.log({ notif });
    // notif.onclick = () => {
    //   this.log("Notification clicked");
    // };
  };
}
