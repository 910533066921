import { useSearchParams as useReactRouterSearchParams } from "react-router-dom";

function useSearchParams() {
  const [searchParams, setSearchParams] = useReactRouterSearchParams();

  return {
    searchParams,
    setSearchParams,
  };
}

export default useSearchParams;
