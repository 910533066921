import styled from "@emotion/styled";

import { Body } from "@/components/Typography";

export const StyledContainer = styled.div`
  position: relative;
  text-align: center;
  padding-top: 2.5rem;

  .icon {
    position: absolute;
    top: 0;
    left: -0.25rem;
    overflow: hidden;
  }

  .close-button {
    position: absolute;
    top: -0.15rem;
    right: -0.5rem;
  }
`;

export const StyledContent = styled(Body)`
  color: #4e5876;
`;

export const StyledContentItalic = styled(StyledContent)`
  font-style: italic;
`;
