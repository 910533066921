import lodashGet from "lodash/get";
import lodashSet from "lodash/set";

class AppSettings {
  _settings: Record<string, any> = {};

  constructor() {
    this.loadSettingFromLocalStorage();
  }

  //----------------------------

  loadSettingFromLocalStorage = () => {
    try {
      this._settings = JSON.parse(
        localStorage.getItem("PAYPIPE.SETTINGS") || "{}"
      );
    } catch {}
  };

  saveSettingToLocalStorage = () => {
    try {
      localStorage.setItem("PAYPIPE.SETTINGS", JSON.stringify(this._settings));
    } catch {}
  };

  //----------------------------

  set = (key: string, value: any) => {
    lodashSet(this._settings, key, value);
    this.saveSettingToLocalStorage();
  };

  get = (key: string, defaultValue?: any) => {
    return lodashGet(this._settings, key, defaultValue);
  };
}

export default AppSettings;
