"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import throttle from "lodash/throttle";
import styled from "@emotion/styled/macro";
import { usePreventScroll } from "react-aria";

import Logo from "@/features/app/Logo";
import Button from "@/components/input/Button";
import { EXTERNAL_PATHS, SITE_PATHS } from "@/config/routing";
import {
  BREAKPOINT_NAMES,
  screenLargerThan,
  screenSmallerThan,
  useResponsive,
} from "@/styles";
import { squareSizing } from "@/utils/css";

const BREAK_POINT = "tablet" satisfies BREAKPOINT_NAMES;
const STICKY_HEADER = false;

//--------------------------

const OPTIONS: { content: React.ReactNode; link: string }[] = [
  {
    content: "Pricing",
    link: `${EXTERNAL_PATHS.PAYPIPE_SITE}#pricing`,
  },
  {
    content: "Contact",
    link: `${EXTERNAL_PATHS.PAYPIPE_SITE}contact`,
  },
  {
    content: "Blog",
    link: `${EXTERNAL_PATHS.PAYPIPE_SITE}blog`,
  },
];

//--------------------------

const StyledMenuInput = styled.input`
  display: none;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1420px !important;
  height: var(--header-height, 5rem);
  padding: 1rem;

  ${screenLargerThan[BREAK_POINT]} {
    gap: 24rem;
  }
`;

const StyledNav = styled.nav`
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .navlink {
    display: block;
    padding: 0.25rem 1rem;
    color: var(--color) !important;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none !important;
  }

  ${screenLargerThan[BREAK_POINT]} {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    ul {
      display: flex;
      gap: 0.25rem;
    }

    .navlink {
      display: inline-block;
    }
  }
`;

const StyledLogo = styled(Logo)`
  filter: brightness(0) invert(1);
  transition: filter 320ms ease;
`;

const StyledCtaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > * {
    padding-block: 0.75rem;
    border-radius: 10rem !important;
  }

  ${screenLargerThan.smallMonitor} {
    .primary {
      padding-inline: 2rem;
    }
  }
`;

const StyledMenuButton = styled.label`
  position: relative;
  color: var(--color);
`;

const StyledHamburgerIcon = styled.div`
  ${squareSizing("1.75rem")};
  position: relative;
  --height: 3px;
  --color: #fff;

  .bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 1;
    transform-origin: center;
    transition: transform 240ms ease-out, opacity 300ms ease-out;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      height: var(--height);
      background: var(--color);
      border: 10rem;
      width: 100%;
      transform: translate(-50%);
      border-radius: 10rem;
    }

    &:first-child,
    &:last-child {
      &::after {
        width: 60%;
      }
    }

    &:first-child {
      transform: translateY(35%);

      &::after {
        left: 0;
        transform: translateX(0);
      }
    }

    &:nth-child(2) {
      transform: translateY(0);
    }

    &:last-child {
      transform: translateY(-35%);

      &::after {
        right: 0;
        transform: translateX(-10%);
      }
    }
  }
`;

const StyledMenu = styled.div`
  position: fixed;
  top: 1rem;
  left: 0.875rem;
  width: calc(100% - 1.75rem);
  background: #fff;
  border-radius: 0.5rem;
  opacity: 0;
  transform: translateY(-1rem);
  transition: all ease-out 140ms;
  padding-block: 1rem;
  --color: #000;
  pointer-events: none;

  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 1rem;
    margin-bottom: 1.5rem;
  }

  ${StyledCtaContainer} {
    display: flex;
    padding-inline: 1rem;
    margin-top: 1.5rem;

    .primary {
      flex-grow: 3;
      color: #fff;
      background: var(--clr-primary-100, #5f57ff);
      border: 1px solid var(--clr-primary-100, #5f57ff);
    }

    .secondary {
      flex-grow: 1;
      color: #000;
      border: 1px solid #000;
    }
  }

  ${StyledNav} {
    li + li {
      margin-top: 1rem;
    }
  }

  ${StyledHamburgerIcon} {
    --color: #000;
  }
`;

const StyledHeader = styled.div`
  --color: #fff;
  ${STICKY_HEADER
    ? `
        position: fixed;
        top: 0;
        left: 0;
      `
    : `
        position: relative;
        margin-bottom: calc(-1 * var(--header-height));
      `}
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  z-index: 10;
  transition: all 240ms ease;

  &.scolled {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
  }

  ${screenSmallerThan[BREAK_POINT]} {
    ${StyledMenuInput}:checked + ${StyledContainer} {
      ${StyledMenu} {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
      }

      ${StyledHamburgerIcon} {
        --color: #555;

        .bar {
          &:first-child {
            transform: translateX(1.75%) translateY(-2.5%) rotate(135deg);

            &::after {
              width: 100%;
            }
          }

          &:nth-child(2) {
            transform: translateX(-5%) translateY(-2.5%) rotate(-135deg);
          }

          &:last-child {
            transform: translateX(50%) translateY(-35%);
            opacity: 0;
          }
        }
      }

      ${StyledLogo} {
        filter: none;
      }
    }
  }
`;

const StyledOverlay = styled.button`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 9;
  border: none;
  outline: none;
`;

//--------------------------

const HamburderIcon = () => (
  <StyledHamburgerIcon>
    <span className="bar" />
    <span className="bar" />
    <span className="bar" />
  </StyledHamburgerIcon>
);

const Header: React.FC = () => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const { isScreenSmallerThanTablet } = useResponsive();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  usePreventScroll({
    isDisabled: !isMenuOpen,
  });

  const close = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  useEffect(() => {
    if (!STICKY_HEADER) {
      return;
    }

    const callback = () => {
      const top = document.body.getBoundingClientRect().top;

      if (headerRef.current) {
        if (top > -50) {
          headerRef.current.classList.remove("scolled");
        } else {
          headerRef.current.classList.add("scolled");
        }
      }
    };

    const callbackThrottled = throttle(callback, 250);

    document.addEventListener("scroll", callbackThrottled);

    return () => {
      document.removeEventListener("scroll", callbackThrottled);
    };
  }, []);

  const navJsx = (
    <StyledNav onClick={close}>
      <ul>
        {OPTIONS.map(({ content, link }, i) => (
          <li key={i}>
            <a className="navlink" target="_blank" rel="noreferrer" href={link}>
              {content}
            </a>
          </li>
        ))}
      </ul>
    </StyledNav>
  );

  const ctaJsx = (
    <StyledCtaContainer>
      <Button
        openInNewTab
        variant="ghost"
        colorVariant="white-primary"
        className="secondary"
        link={`${EXTERNAL_PATHS.PAYPIPE_APP}${SITE_PATHS.HEADLESS_LOGIN_PAGE}`}
      >
        Login
      </Button>
      <Button
        openInNewTab
        colorVariant="white-primary"
        className="primary"
        link={`${EXTERNAL_PATHS.PAYPIPE_APP}${SITE_PATHS.HEADLESS_REGISTER_PAGE}`}
      >
        Sign up for free
      </Button>
    </StyledCtaContainer>
  );

  const menuJsx = (
    <StyledMenu>
      <div className="top-container">
        <StyledLogo variant="plain" onClick={close} />
        <StyledMenuButton htmlFor="menu">
          <HamburderIcon />
        </StyledMenuButton>
      </div>

      {navJsx}

      {ctaJsx}
    </StyledMenu>
  );

  const logoJsx = <StyledLogo openInNewTab redirectToMainSite />;

  if (isScreenSmallerThanTablet) {
    return (
      <>
        <StyledHeader ref={headerRef}>
          <StyledMenuInput
            id="menu"
            type="checkbox"
            checked={isMenuOpen}
            onChange={(e) => {
              setIsMenuOpen(e.target.checked);
            }}
          />

          <StyledContainer className="container">
            {logoJsx}

            <StyledMenuButton htmlFor="menu">
              <HamburderIcon />
            </StyledMenuButton>

            {menuJsx}
          </StyledContainer>
        </StyledHeader>

        {isMenuOpen && <StyledOverlay onClick={close} />}
      </>
    );
  }

  return (
    <StyledHeader ref={headerRef}>
      <StyledContainer className="container">
        {logoJsx}

        {navJsx}

        {ctaJsx}
      </StyledContainer>
    </StyledHeader>
  );
};

export default Header;
