import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { EXTERNAL_PATHS, SITE_PATHS } from "@/config/routing";
import { useResponsive } from "@/styles";

import { ILogoProps } from "./Logo.types";
import { LOGO_CONFIG } from "./Logo.config";

const StyledLink = styled(Link)`
  display: inline-block;
`;

const StyledImage = styled.img`
  width: var(--width);
  height: var(--height);
`;

const Logo: React.FC<ILogoProps> = ({
  className,
  redirectToMainSite,
  variant = "plain",
  onClick,
  openInNewTab,
}) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  const { src, height, width, desktopHeight, desktopWidth } =
    LOGO_CONFIG[variant];

  return (
    <StyledLink
      to={
        redirectToMainSite ? EXTERNAL_PATHS.PAYPIPE_SITE : SITE_PATHS.HOME_PAGE
      }
      onClick={onClick}
      {...(redirectToMainSite || openInNewTab ? { target: "_blank" } : {})}
    >
      <StyledImage
        alt="Paypipe logo"
        className={className}
        src={src}
        style={
          {
            width: isScreenSmallerThanTablet ? width : desktopWidth,
            height: isScreenSmallerThanTablet ? height : desktopHeight,
          } as React.CSSProperties
        }
      />
    </StyledLink>
  );
};

export default Logo;
