"use client";
import { screenSmallerThan } from "@/styles";
import styled from "@emotion/styled";

export const StyledHeader = styled.header`
  background: var(--clr-primary-100, #5f57ff);

  .container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 2rem 0;
  }

  ${screenSmallerThan.tablet} {
    padding-inline: 1rem;
  }
`;

export const StyledLogoContainer = styled.div`
  margin-inline-end: auto;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--clr-text-900, #0d0d12);
  letter-spacing: -0.03em;
  background: var(--clr-primary-100, #5f57ff);
`;
