import styled from "@emotion/styled";

import RadioGroup from "@/components/input/RadioGroup";
import { squareSizing } from "@/utils/css";

export const StyledContainer = styled(RadioGroup.Container)`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

export const StyledItem = styled.label<{ $isSelected: boolean }>`
  ${squareSizing("2.25rem")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ $isSelected }) =>
      $isSelected
        ? "var(--clr-primary-100, #5f57ff)"
        : "var(--clr-primary-50, #a19cf0)"};
  border-radius: 10rem;
  background: ${({ $isSelected }) =>
    $isSelected
      ? "var(--clr-primary-100, #5f57ff)"
      : "var(--clr-background-white, #fff)"};
  color: ${({ $isSelected }) =>
    $isSelected ? "#fff" : "var(--clr-primary-100, #5f57ff)"};
  cursor: pointer;

  input {
    display: none;
  }
`;
