import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from "@lexical/markdown";

import { Value } from "../Wysiwyg.types";
import { $setSelection } from "lexical";

const ControlledComponentPlugin: React.FC<{
  value: Value;
}> = ({ value }) => {
  const [editor] = useLexicalComposerContext();

  const markdownFromProps = (value?.markdown || "").trim();

  useEffect(() => {
    editor.update(() => {
      const localMarkdown = (
        $convertToMarkdownString(TRANSFORMERS) || ""
      ).trim();

      if (markdownFromProps !== localMarkdown) {
        $convertFromMarkdownString(markdownFromProps, TRANSFORMERS);
        $setSelection(null);
        editor.focus(undefined, { defaultSelection: "rootEnd" });
      }
    });
  }, [editor, markdownFromProps]);

  return null;
};

export default ControlledComponentPlugin;
