import React from "react";
import styled from "@emotion/styled";

import Wysiwyg from "@/components/input/LexicalWysiwyg";
import { screenLargerThan } from "@/styles";

const StyledAboutMe = styled(Wysiwyg)`
  font-size: 1rem;
  line-height: 1.5em;

  ${screenLargerThan.widescreen} {
    font-size: 1.125rem;
  }
`;

const AboutMe: React.FC<{
  children: string;
  className?: string;
}> = ({ children, className }) => {
  return (
    <StyledAboutMe
      readOnly
      showFullContent
      className={className}
      value={{ markdown: children }}
      contentStyle={{ padding: 0 }}
    />
  );
};

export default AboutMe;
