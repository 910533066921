import React from "react";
import styled from "@emotion/styled";

import { Package } from "@/services/UserService";
import Slider from "@/features/PaypipeId/Slider";
import PackageCard from "@/features/PaypipeId/PackageCard";
import { screenLargerThan, useResponsive } from "@/styles";

export const StyledContainer = styled.div`
  ${screenLargerThan.tablet} {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > * {
      flex-grow: 1;
      max-width: 36rem;
    }
  }
`;

const Packages: React.FC<{
  packages: Package[];
  className?: string;
}> = ({ packages, className }) => {
  const { isScreenSmallerThanTablet } = useResponsive();

  if (!packages.length) {
    return null;
  }

  if (
    packages.length === 1 ||
    (!isScreenSmallerThanTablet && packages.length === 2)
  ) {
    return (
      <StyledContainer className={className}>
        {packages.map((p, i) => (
          <PackageCard key={i} package={p} className="mb-3" />
        ))}
      </StyledContainer>
    );
  }

  return (
    <Slider className={className}>
      {packages.map((p, i) => (
        <PackageCard key={i} package={p} className="mb-3" />
      ))}
    </Slider>
  );
};

export default Packages;
