import ClaimIdsection from "./components/ClaimIdsection";
import HeroSection from "./components/HeroSection";
import PricingSection from "./components/PricingSection";
import Section from "./components/Section";
import UserSection from "./components/UserSection";
import { StyledBr, StyledMainLayout } from "./LandingPage.styles";

const LandingPage: React.FC = () => {
  return (
    <StyledMainLayout>
      <HeroSection />

      <Section
        image="/assets/pages/landing/section-1.jpg"
        heading={
          <>
            Build your <StyledBr />
            Paypipe.me page
            <StyledBr /> in minutes
          </>
        }
        content="At Paypipe, we're in the business of helping you shine. Think of your Paypipe.me page as your own website. "
      />

      <Section
        image="/assets/pages/landing/section-2.jpg"
        heading="Showcase your skills"
        content="List your skills, experience and portfolio to showcase your talent to prospective clients."
      />

      <Section
        image="/assets/pages/landing/section-3.jpg"
        heading="Offer value with custom packages"
        content="Bundle your most popular services into a simple package, available for clients to request on the spot."
      />

      <Section
        image="/assets/pages/landing/section-4.jpg"
        heading="Get paid on time, every time"
        content="Go from 0 to securing payment in no time. Turn leads into clients and offers into completed jobs, all in one place!"
      />

      <Section
        image="/assets/pages/landing/section-5.jpg"
        heading="Build credibility with reviews"
        content="Reviews you receive from clients will be published on your Paypipe.me page to build your personal brand and credibility."
      />

      <Section
        image="/assets/pages/landing/section-6.jpg"
        heading="Shine a light on you!"
        content="Share your Paypipe.me link with your network and add it to all of your public profiles and pages."
      />

      <UserSection />

      <PricingSection />

      <ClaimIdsection />
    </StyledMainLayout>
  );
};

export default LandingPage;
