import React, { useEffect } from "react";

import { getUserFullName, UserInfo, UserProfile } from "@/services/UserService";
import { IS_STAGING_ENV } from "@/config/app";
import UserProfileImage from "@/features/PaypipeId/UserProfileImage";
import Location from "@/features/PaypipeId/Location";
import SocialLinks from "@/features/PaypipeId/SocialLinks";
import { ANIMATION_CLASSNAMES } from "@/styles";
import Section from "@/features/PaypipeId/Section";
import SplashScreen from "@/features/pages/app/SplashScreen";
import Icon from "@/components/misc/Icon";
import CtaSection from "@/features/PaypipeId/CtaSection";
import ClaimIdsection from "@/features/PaypipeId/ClaimIdsection";
import PageHeading from "@/features/PaypipeId/PageHeading";
import ProfileStats from "@/features/PaypipeId/ProfileStats";
import Experiences from "@/features/PaypipeId/Experiences";
import Skills from "@/features/PaypipeId/Skills";
import Packages from "@/features/PaypipeId/Packages";
import Portfolios from "@/features/PaypipeId/Portfolios";
import AboutMe from "@/features/PaypipeId/AboutMe";
import ShareButton from "@/features/media/ShareButton";
import { EXTERNAL_PATHS, SITE_PATHS } from "@/config/routing";
import Button from "@/components/input/Button";

import {
  StyledContactButton,
  StyledBoldText,
  StyledContainer,
  StyledContentSection,
  StyledId,
  StyledIdContainer,
  StyledInnerContainer,
  StyledPage,
  StyledPrimaryInfoSection,
  StyledUsername,
  StyledMobileCtaSection,
} from "./PaypipeIdPage.styles";

const PaypipeIdPage: React.FC<{
  loading?: boolean;
  accountData: UserInfo;
  profileData: UserProfile;
}> = ({ loading, accountData, profileData }) => {
  useEffect(() => {
    if (IS_STAGING_ENV) {
      console.log("DEBUG", {
        accountData,
        profileData,
      });
    }
  }, [accountData, profileData]);

  if (loading) {
    return <SplashScreen />;
  }

  const userName = getUserFullName(accountData);
  const { paypipe_id: paypipeId } = accountData;
  const {
    location = "",
    packages = [],
    portfolios = [],
    skills_new: skills = [],
    specializations: experiences = [],
    about_me: aboutMe = "",
    heading = "",
    links: socialLinks = [],
  } = profileData;

  if (!paypipeId) {
    return <SplashScreen />;
  }

  return (
    <StyledPage className={ANIMATION_CLASSNAMES.FADE_IN}>
      <StyledContainer className="container">
        <StyledInnerContainer>
          <StyledPrimaryInfoSection>
            <div className="outer-container">
              <UserProfileImage
                className="profile-image"
                src={accountData.picture || ""}
                givenName={accountData.given_name || ""}
                familiyName={accountData.family_name || ""}
              />

              <div>
                <StyledUsername>{userName}</StyledUsername>
                {(!!paypipeId || !!location) && (
                  <StyledIdContainer className="mt-3">
                    {!!paypipeId && <StyledId>@{paypipeId}</StyledId>}
                    {!!location && <Location location={location} />}
                  </StyledIdContainer>
                )}
              </div>
            </div>

            {!!socialLinks.length && (
              <SocialLinks className="mt-4" links={socialLinks} />
            )}

            <StyledContactButton
              openInNewTab
              className="w-100 mt-4"
              borderRadius="round"
              link={`${EXTERNAL_PATHS.PAYPIPE_APP}${SITE_PATHS.CONTACT_PAGE}?paypipe_id=${paypipeId}`}
            >
              <Icon src="assets/pages/paypipe-id/contact.svg" />
              Get In Touch
            </StyledContactButton>
          </StyledPrimaryInfoSection>

          <StyledContentSection>
            {!!heading && <PageHeading>{heading}</PageHeading>}

            {!!aboutMe && (
              <Section title="About me" className="mt-5">
                <AboutMe>{aboutMe}</AboutMe>
              </Section>
            )}

            <ProfileStats className="mt-4" />

            {!!experiences.length && (
              <Section
                className="mt-5"
                icon="assets/pages/paypipe-id/box.svg"
                title="My Experience"
              >
                <Experiences experiences={experiences} />
              </Section>
            )}

            {!!skills.length && (
              <>
                <StyledBoldText className="mt-5">Why hire me?</StyledBoldText>
                <Section
                  className="mt-4"
                  icon="assets/pages/paypipe-id/tools.svg"
                  title="My Skills & Tools"
                >
                  <Skills skills={skills} />
                </Section>
              </>
            )}

            {!!portfolios.length && (
              <Section
                className="mt-5"
                icon="assets/pages/paypipe-id/work.svg"
                title="My Recent Work"
              >
                <Portfolios portfolios={portfolios} />
              </Section>
            )}

            {!!packages.length && (
              <Section
                className="mt-5"
                icon="assets/pages/paypipe-id/package.svg"
                title="My Packages"
              >
                <StyledBoldText>Popular jobs in one package.</StyledBoldText>

                <Packages className="mt-4" packages={packages} />
              </Section>
            )}
          </StyledContentSection>
        </StyledInnerContainer>

        <CtaSection paypipeId={paypipeId} className="mt-5" />

        <ClaimIdsection className="mt-5" />

        <StyledMobileCtaSection>
          <ShareButton
            buttonProps={{
              borderRadius: "default",
              children: (
                <>
                  Share my Profile
                  <Icon
                    src="/assets/pages/paypipe-id/share.svg"
                    size="sm"
                    colorVariant="white"
                  />
                </>
              ),
            }}
            content={{
              text: heading,
              url: `${EXTERNAL_PATHS.PAYPIPE_ID_SITE}/${paypipeId}`,
            }}
          />

          <Button
            colorVariant="white-primary"
            borderRadius="default"
            style={{
              color: "#000000",
            }}
          >
            <Icon src="assets/pages/paypipe-id/paypipe.svg" size="sm" />
            Create Your Paypipe ID
            <Icon src="assets/pages/paypipe-id/open.svg" size="sm" />
          </Button>
        </StyledMobileCtaSection>
      </StyledContainer>
    </StyledPage>
  );
};

export default PaypipeIdPage;
