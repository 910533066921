import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import SliderComponent, { Settings } from "react-slick";
import isArray from "lodash/isArray";
import { squareSizing } from "@/utils/css";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { joinClassNames } from "@/utils/classNames";

//-----------------------------

const StyledContainer = styled.div`
  padding-bottom: 1rem;

  .slick-list {
    margin-inline: -0.75rem;
  }

  .item-container {
    padding-inline: 0.75rem;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 4.125rem;

    .arrow {
      ${squareSizing("4.125rem")};
      border-radius: 10rem;

      &.arrow-right {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    .dots {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      height: 100%;

      .dot {
        position: relative;
        height: 100%;
        width: 1.875rem;

        &.active {
          width: 4.375rem;

          &::before {
            background: var(--clr-primary-100, #5f57ff);
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: 0.75rem;
          width: 100%;
          border-radius: 10rem;
          background: #d9d8f9;
          opacity: 1;
        }
      }
    }
  }
`;

var settings: Settings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplaySpeed: 0,
  touchThreshold: 100,
  swipe: false,

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

//-----------------------------

type ISliderProps = {
  children: React.ReactNode;
  className?: string;
};

//-----------------------------

const Slider: React.FC<ISliderProps> = ({
  children: childrenFromProps = [],
  className,
}) => {
  const [containerRef, setContainerRef] = useState<SliderComponent | null>(
    null
  );
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const children = useMemo(
    () =>
      isArray(childrenFromProps) ? childrenFromProps : [childrenFromProps],
    [childrenFromProps]
  );

  if (children.length === 1) {
    return <StyledContainer className={className}>{children}</StyledContainer>;
  }

  return (
    <StyledContainer className={className}>
      <SliderComponent
        {...settings}
        ref={setContainerRef}
        beforeChange={(_, i) => {
          setCurrentItemIndex(i);
        }}
      >
        {children.map((item, i) => (
          <div className="item-container" key={i}>
            {item}
          </div>
        ))}
      </SliderComponent>

      <div className="controls mt-3">
        <Button
          className="arrow arrow-left"
          colorVariant="white-primary"
          onClick={() => {
            if (containerRef) {
              containerRef.slickPrev();
            }
          }}
        >
          <Icon
            isSrcRelative
            className="icon"
            src="chevron_left.svg"
            colorVariant="primary"
          />
        </Button>

        <div className="dots">
          {children.map((_, i) => (
            <Button
              className={joinClassNames(
                "dot",
                i === currentItemIndex && "active"
              )}
              variant="ghost"
              key={i}
              onClick={() => {
                if (containerRef) {
                  containerRef.slickGoTo(i);
                }
              }}
            />
          ))}
        </div>

        <Button
          className="arrow arrow-right"
          colorVariant="white-primary"
          onClick={() => {
            if (containerRef) {
              containerRef.slickNext();
            }
          }}
        >
          <Icon
            isSrcRelative
            className="icon"
            src="chevron_left.svg"
            colorVariant="primary"
          />
        </Button>
      </div>
    </StyledContainer>
  );
};

export default Slider;
