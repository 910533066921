import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Client, ClientOptions, BaseTransportOptions } from "@sentry/types";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { APP_ENV } from "@/config/app";
import packageJson from "@/../package.json";

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN!;

class ErrorReporting {
  sentryClient: null | Client<ClientOptions<BaseTransportOptions>> = null;

  init = () => {
    if (!SENTRY_DSN) {
      return;
    }

    this.sentryClient =
      Sentry.init({
        dsn: SENTRY_DSN,
        debug: true,
        release: packageJson.version,
        environment: APP_ENV,
        integrations: [
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      }) || null;
  };

  setUser = (data?: {
    username?: string;
    id?: string | number;
    email?: string;
  }) => {
    if (this.sentryClient && data) {
      Sentry.setUser(data);
    }
  };

  report = (err: any) => {
    if (this.sentryClient && err) {
        this.sentryClient.captureException(err);
    }
  };
}

export default ErrorReporting;
