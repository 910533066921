import { useCallback, useEffect, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import {
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";

import Icon from "@/components/misc/Icon";

import { StyledButton, StyledContainer } from "./ToolbarPlugin.styles";
import LinkButton from "../../components/LinkButton/LinkButton";

const LowPriority = 1;

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [isBold, setIsBold] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isList, setIsList] = useState(false);

  const [blockType, setBlockType] = useState("paragraph");

  //-------------------------------

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsUnderline(selection.hasFormat("strikethrough"));

      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      // Update lists
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
          setIsList(true);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          setBlockType(type);
          setIsList(false);
        }
      }
    }
  }, [editor]);

  const handleBoldClick = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
  }, [editor]);

  const handleUnderlineClick = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
  }, [editor]);

  const handleListClick = useCallback(() => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [editor, blockType]);

  //-------------------------------

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  //-------------------------------

  return (
    <StyledContainer ref={toolbarRef} className="toolbar">
      <StyledButton
        $active={isBold}
        onClick={handleBoldClick}
        aria-label="Format Bold"
      >
        <Icon
          isSrcRelative
          src="bold.svg"
          size="xs"
          colorVariant={isBold ? "primary" : "black"}
        />
      </StyledButton>

      <StyledButton
        $active={isList}
        aria-label="Format list"
        onClick={handleListClick}
      >
        <Icon
          isSrcRelative
          src="list.svg"
          size="xs"
          colorVariant={isList ? "primary" : "black"}
        />
      </StyledButton>

      <StyledButton
        $active={isUnderline}
        onClick={handleUnderlineClick}
        aria-label="Format Underline"
      >
        <Icon
          isSrcRelative
          src="underline.svg"
          size="xs"
          colorVariant={isUnderline ? "primary" : "black"}
        />
      </StyledButton>

      <LinkButton />

      <span />
    </StyledContainer>
  );
}
