import React from "react";
import styled from "@emotion/styled";

import {
  getPackageDurationFormatted,
  MediaFile,
  Package,
} from "@/services/UserService";
import { useFileSignedUrl } from "@/services/ContractsService";
import ShimmerLoader from "@/components/misc/ShimmerLoader";
import Slider from "@/components/misc/Slider";
import { screenLargerThan } from "@/styles";
import Wysiwyg from "@/components/input/LexicalWysiwyg";

//-----------------------------

const StyledContainer = styled.div`
  position: relative;

  .actions-section {
    position: relative;
    z-index: 2;
    pointer-events: none !important;

    & > * {
      pointer-events: all;
    }
  }

  .image {
    position: relative;
    height: 28rem;
    max-height: 45vh;
    background-size: cover;
    background-position: center;
    border-radius: 0.625rem;
    background-color: #f1f1f1;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    word-break: break-word;
    padding-right: 2rem;

    ${screenLargerThan.tablet} {
      font-size: 2rem;
    }
  }

  .description {
    font-size: 1.125rem;
    font-weight: 400;
  }

  .details {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .label {
    font-size: 1.125rem;
    font-weight: 400;
  }

  .value {
    font-size: 2rem;
    font-weight: 400;
    color: var(--clr-primary-100, #5f57ff);
  }
`;

const StyledImage = styled.div`
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.44));
  background-size: cover;
  background-position: center;
  height: 28rem;
  max-height: 45vh;
`;

const StyledImages = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 28rem;
  max-height: 45vh;
  border-radius: inherit;
  z-index: 1;

  & > * {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
  }
`;

//-----------------------------

const Image: React.FC<{ image: MediaFile }> = ({ image }) => {
  const { url, isLoading } = useFileSignedUrl(image.url || "");

  if (isLoading) {
    return (
      <ShimmerLoader.Default
        style={{ height: "24rem", borderRadius: "0.625rem" }}
      />
    );
  }

  return (
    <StyledImage
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.44)), url(${url})`,
      }}
    />
  );
};

const Images: React.FC<{ images: MediaFile[] }> = ({ images }) => {
  if (images.length === 1) {
    const firstImage = images[0];
    return (
      <StyledImages>
        <Image key={firstImage.id} image={firstImage} />
      </StyledImages>
    );
  }

  return (
    <StyledImages>
      <Slider>
        {images.map((image) => (
          <Image key={image.id} image={image} />
        ))}
      </Slider>
    </StyledImages>
  );
};

const PackagePreview: React.FC<{ package: Package }> = ({
  package: packageDetails,
}) => {
  const { name, attachments = [], description, price } = packageDetails;

  return (
    <StyledContainer>
      <div className="title">{name}</div>

      <div className="image mt-3">
        <Images images={attachments} />
      </div>

      <div className="details mt-4">
        <div>
          <div className="label">Fixed price</div>
          <div className="value mt-2">${price}</div>
        </div>

        <div>
          <div className="label">Delivery Time</div>
          <div className="value mt-2">
            {getPackageDurationFormatted(packageDetails)}
          </div>
        </div>
      </div>

      <Wysiwyg
        readOnly
        unstyled
        showFullContent
        className="mt-4 description"
        value={{ markdown: description }}
        initialValue={{ markdown: description }}
      />
    </StyledContainer>
  );
};

export default PackagePreview;
