import React from "react";
import styled from "@emotion/styled";

import Icon from "@/components/misc/Icon";

//-----------------------------

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  padding: 2rem 1.5rem;
  border: 1px solid #dedede;
  gap: 1rem;

  .content {
    flex-grow: 1;
  }

  .title {
    font-size: 1.125rem;
    font-weight: 400;
    min-width: max-content;
  }

  .value {
    position: relative;
    top: -10%;
    font-size: 3.375rem;
    font-weight: 600;
    height: 1em;
  }
`;

//-----------------------------

type IStatCardProps = {
  title: React.ReactNode;
  value: React.ReactNode;
  icon: string;
  className?: string;
  isIconSrcRelative?: boolean;
};

//-----------------------------

const StatCard: React.FC<IStatCardProps> = ({
  title,
  value,
  icon,
  className,
  isIconSrcRelative = false,
}) => {
  return (
    <StyledContainer className={className}>
      <div className="content">
        <Icon
          isSrcRelative={isIconSrcRelative}
          customSize="3rem"
          colorVariant="black"
          src={icon}
        />
        <div className="title mt-3">{title}</div>
      </div>

      <div className="value">{value}</div>
    </StyledContainer>
  );
};

export default StatCard;
