import React from "react";
import { useToast } from "@react-aria/toast";

import Icon from "@/components/misc/Icon";

import { IToastProps } from "./Toast.types";
import {
  StyledCloseButton,
  StyledDescription,
  StyledIcon,
  StyledTitle,
  StyledToast,
} from "./Toast.styles";

const Toast: React.FC<IToastProps> = ({ toast, state }) => {
  const ref = React.useRef(null);
  const {
    toastProps,
    contentProps,
    titleProps,
    closeButtonProps,
    descriptionProps,
  } = useToast({ toast }, state, ref);

  const { title, description, variant = "success" } = toast.content;

  return (
    <StyledToast
      {...toastProps}
      ref={ref}
      data-animation={toast.animation}
      onAnimationEnd={() => {
        if (toast.animation === "exiting") {
          state.remove(toast.key);
        }
      }}
    >
      <StyledIcon
        isSrcRelative
        src={variant === "error" ? "toast_error.svg" : "toast_success.svg"}
        customSize="2.5rem"
      />

      <div {...contentProps}>
        <StyledTitle size="lg" {...titleProps}>
          {title}
        </StyledTitle>
        {!!description && (
          <StyledDescription size="md" {...descriptionProps}>
            {description}
          </StyledDescription>
        )}
      </div>
      <StyledCloseButton useAria variant="ghost" {...closeButtonProps}>
        <Icon isSrcRelative src="cross.svg" customSize="0.75rem" />
      </StyledCloseButton>
    </StyledToast>
  );
};

export default Toast;
