import React from "react";
import styled from "@emotion/styled";

import { Portfolio } from "@/services/UserService";
import PortfolioCard from "@/features/PaypipeId/PortfolioCard";
import { screenLargerThan } from "@/styles";

const StyledContainer = styled.div`
  display: grid;
  gap: 1.5rem;

  ${screenLargerThan.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
`;

const Portfolios: React.FC<{
  portfolios: Portfolio[];
  className?: string;
}> = ({ portfolios, className }) => {
  if (!portfolios.length) {
    return null;
  }

  return (
    <StyledContainer className={className}>
      {portfolios.map((p, i) => (
        <PortfolioCard key={i} portfolio={p} />
      ))}
    </StyledContainer>
  );
};

export default Portfolios;
