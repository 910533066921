import lodashGet from "lodash/get";

import { appFeatures, appSettings } from "@/config/services";

import { Theme, ThemeDetails } from "./ThemeManager.types";
import THEMES from "./themes";

export function getThemeCssVariables(theme: ThemeDetails) {
  const variables: Record<string, any> = {};

  function resolveVariables(
    parentKey: string,
    config: Object,
    valueAccessKey: string,
    varPrefix: string
  ) {
    if (!config || !Object.keys(config).length) {
      return;
    }

    const hexValue = lodashGet(config, valueAccessKey);

    if (hexValue) {
      const variableName = `--${varPrefix}-${parentKey.replaceAll(".", "-")}`;
      variables[variableName] = hexValue;
      return;
    }

    Object.keys(config).forEach((key) => {
      const joinedKey = [parentKey, key].filter(Boolean).join(".");
      resolveVariables(
        joinedKey,
        lodashGet(config, key),
        valueAccessKey,
        varPrefix
      );
    });
  }

  resolveVariables("", theme.colors, "hex", "clr");
  resolveVariables("", theme.filters, "value", "filter");

  return variables;
}

export function checkIsDarkSchemePreferred() {
  return window?.matchMedia?.("(prefers-color-scheme:dark)")?.matches ?? false;
}

export function getDefaultTheme(): Theme {
  let theme = appSettings.get("THEME");

  if (!theme) {
    if (appFeatures.isSupported("CUSTOMIZATION.DARK_MODE")) {
      theme = checkIsDarkSchemePreferred() ? Theme.Dark : Theme.Light;
    } else {
      theme = Theme.Light;
    }
  }

  return theme;
}

export function setThemeVars(name: Theme) {
  const themeDetails = lodashGet(THEMES, name) as ThemeDetails;
  if (themeDetails) {
    const themeCssVars = getThemeCssVariables(themeDetails);

    Object.entries(themeCssVars).forEach(([varName, value]) => {
      document.documentElement.style.setProperty(varName, value);
    });
  }
}
