import { IS_STAGING_ENV } from "@/config/app";

import { FeatureName, FeatureSetting } from "./AppFeatures.types";

export const FEATURE_FLAGS: Partial<Record<FeatureName, FeatureSetting>> = {
  "DESKTOP_LAYOUT.CUSTOMER_SERVICE_CARD": { supported: true },
  //-------------------
  "DASHBOARD.CONTRACTS_TABLE": { supportedOnDesktop: true },
  "DASHBOARD.CONTRACT_MANAGEMENT_CARD": { supportedOnDesktop: true },
  //-------------------
  "ACCOUNT.EDIT_LANGUAGES": { supported: true },
  "ACCOUNT.USE_CLIENT_TIMEZONE_AS_DEFAULT": { supported: true },
  "ACCOUNT.PORTFOLIOS": { supported: IS_STAGING_ENV },
  "ACCOUNT.REVIEWS": { supported: false },
  "ACCOUNT.PROFESSIONS": { supported: false },
  "ACCOUNT.EXPERIENCE": { supported: false },
  "ACCOUNT.PACKAGES": { supported: IS_STAGING_ENV },
  "ACCOUNT.SHOW_NEW_PAYPIPE_ID_EDIT_PAGE": { supported: IS_STAGING_ENV },
  //-------------------
  "CUSTOMIZATION.DARK_MODE": { supported: false },
  //-------------------
  "OFFER.FORM.CLIENT": {
    supported: false,
  },
  //-------------------
  "NOTIFICATION.PUSH_NOTIFICATIONS": {
    supported: true,
  },
  //-------------------
  "CONTRACT.REJECT": {
    supported: false,
  },
  "CONTRACT.HIRE_SP_AGAIN": {
    supported: false,
  },
  "CONTRACT.AUTHENTICATE_BEFORE_OFFER_ACCEPTANCE": {
    supported: true,
  },
  "CONTRACT.PREFILL_PAYMENT_PLATFORM_EMAIL": {
    supported: true,
  },
  "CONTRACT.IMPLICIT_START_MILESTONE": {
    supported: true,
  },
  //-------------------
  "CHAT.USE_STREAMIO": {
    supported: true,
  },
  //-------------------
  SUBSCRIPTION: {
    supported: IS_STAGING_ENV,
  },
};
