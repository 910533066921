import React from "react";
import styled from "@emotion/styled";

import ShimmerLoader from "@/components/misc/ShimmerLoader";
import Icon from "@/components/misc/Icon";
import { screenLargerThan } from "@/styles";

//-----------------------------

const StyledContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 0.5rem;

  span {
    line-height: 1em;
    word-break: break-word;
  }

  ${screenLargerThan.widescreen} {
    background: rgba(95, 87, 255, 0.1);
    padding: 0.75rem 0.75rem;
    gap: 0.5rem;
    font-size: 1.125rem;
    min-width: 8rem;

    .icon {
      --size: 1.25rem;
    }
  }
`;

//-----------------------------

type ILocationProps = {
  location?: string;
  className?: string;
  loading?: boolean;
};

//-----------------------------

const Location: React.FC<ILocationProps> = ({
  location,
  className,
  loading,
}) => {
  if (loading) {
    return (
      <StyledContainer as={ShimmerLoader.Default} className={className}>
        <Icon
          isSrcRelative
          className="icon"
          size="xs"
          colorVariant="gray"
          src="location.svg"
        />
        <span
          style={{
            height: "1em",
            flexGrow: "1",
            background: "rgba(0,0,0,0.04)",
            borderRadius: "0.25rem",
          }}
        />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer className={className}>
      <Icon isSrcRelative className="icon" size="xs" src="location.svg" />{" "}
      <span>{location}</span>
    </StyledContainer>
  );
};

export default Location;
