import styled from "@emotion/styled";

import MainLayout from "@/features/layouts/MainLayout";
import { screenSmallerThan } from "@/styles";

export const StyledMainLayout = styled(MainLayout)`
  main {
    --header-height: 5rem;
    min-height: 100vh;
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y proximity;

    & > * {
      scroll-snap-align: start;
    }
  }

  .container {
    max-width: 1420px !important;
  }

  .section {
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
`;

export const StyledBr = styled.div`
  ${screenSmallerThan.tablet} {
    display: none;
  }
`;
