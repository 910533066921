"use client";

import React from "react";

import Header from "@/features/app/Header";

import {
  StyledContainer,
  StyledMain,
  StyledMainContainer,
} from "./DesktopPrimaryLayout.styles";
import { IDesktopPrimaryLayoutProps } from "./DesktopPrimaryLayout.types";

const DesktopPrimaryLayout: React.FC<IDesktopPrimaryLayoutProps> = ({
  children,
  headerProps = {},
}) => {
  return (
    <StyledMainContainer>
      <StyledContainer>
        <Header {...headerProps} />
        <StyledMain className="fade-in">{children}</StyledMain>
      </StyledContainer>
    </StyledMainContainer>
  );
};

export default DesktopPrimaryLayout;
