import { useCallback, useMemo, useRef } from "react";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ListNode, ListItemNode } from "@lexical/list";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import {
  $createParagraphNode,
  $getRoot,
  $setSelection,
  type EditorState,
} from "lexical";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from "@lexical/markdown";

import ToolbarPlugin from "./plugins/ToolbarPlugin/ToolbarPlugin";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ControlledComponentPlugin from "./plugins/ControlledComponentPlugin";
import { MATCHERS, THEME } from "./Wysiwyg.config";
import { isValidUrl } from "./Wysiwyg.utils";
import { IWysiwygProps } from "./Wysiwyg.types";
import {
  StyledContainer,
  StyledContentContainer,
  StyledContentEditable,
  StyledPlaceholder,
} from "./Wysiwyg.styles";

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
  console.error(error);
}

const Wysiwyg: React.FC<IWysiwygProps> = ({
  className,
  value: valueFromProps,
  initialValue,
  onChange,
  readOnly = false,
  focusOnMount = false,
  placeholder = "Type something",
  style,
  showFullContent = false,
  unstyled = false,
  contentStyle,
}) => {
  const valueRef = useRef(valueFromProps);
  valueRef.current = valueFromProps;

  const handleChange = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const markdown = $convertToMarkdownString(TRANSFORMERS);
        if (onChange) onChange({ markdown });
      });
    },
    [onChange]
  );

  const editorConfig: InitialConfigType = useMemo(
    () => ({
      namespace: "MyEditor",
      theme: THEME,
      onError,
      nodes: [LinkNode, AutoLinkNode, ListNode, ListItemNode],
      editorState: () => {
        if (initialValue?.markdown) {
          $convertFromMarkdownString(initialValue?.markdown, TRANSFORMERS);
        }

        $setSelection(null);

        if (focusOnMount) {
          const root = $getRoot();
          if (root.isEmpty()) {
            const paragraph = $createParagraphNode();
            root.append(paragraph);
          }
        }
      },
      editable: !readOnly,
    }),
    [initialValue, readOnly, focusOnMount]
  );

  return (
    <StyledContainer
      $showFullContent={showFullContent}
      $unstyled={unstyled}
      className={className}
      style={style}
    >
      <LexicalComposer initialConfig={editorConfig}>
        {!readOnly && (
          <>
            <ToolbarPlugin />
            <HistoryPlugin />
            <OnChangePlugin onChange={handleChange} />
            <AutoLinkPlugin matchers={MATCHERS} />
            {focusOnMount && <AutoFocusPlugin defaultSelection="rootEnd" />}
          </>
        )}

        {readOnly && <ClickableLinkPlugin newTab />}

        <StyledContentContainer className="editor">
          <RichTextPlugin
            contentEditable={
              <StyledContentEditable id="editor-input" style={contentStyle} />
            }
            placeholder={(editable) =>
              editable ? (
                <StyledPlaceholder>{placeholder}</StyledPlaceholder>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </StyledContentContainer>

        <ListPlugin />
        <LinkPlugin validateUrl={isValidUrl} />

        {!!valueFromProps && (
          <ControlledComponentPlugin value={valueFromProps} />
        )}

        {false && <TreeViewPlugin />}
      </LexicalComposer>
    </StyledContainer>
  );
};

export default Wysiwyg;
