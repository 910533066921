import styled from "@emotion/styled/macro";

import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { squareSizing } from "@/utils/css";

const StyledCard = styled.div`
  padding: 1rem;
  border-radius: 0.625rem;
  background: #fff;
  text-align: center;
  color: #000;
  pointer-events: none;

  .avatar {
    display: block;
    ${squareSizing("5.75rem")};
    border-radius: 10rem;
    margin-inline: auto;
  }

  .title {
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 1rem;
  }

  .secondary-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.625rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: #f8f8f8;
    margin-top: 0.75rem;

    span {
      position: relative;
      bottom: -0.1rem;
    }
  }

  .button {
    margin-top: 1rem;
    border-radius: 10rem;
    gap: 0.5rem;
    font-size: 0.75rem;
    width: 100%;
  }

  .secondary-content {
    font-size: 0.625rem;
    margin-top: 0.75rem;
  }
`;

export type UserDetails = {
  image: string;
  name: string;
  profession: string;
  professionIcon: string;
};

const UserCard: React.FC<
  UserDetails & {
    className?: string;
  }
> = ({ image, name, profession, professionIcon, className }) => (
  <StyledCard className={className}>
    <img className="avatar" src={image} alt="" />
    <div className="title">{name}</div>
    <div className="secondary-title">
      <Icon src={professionIcon} colorVariant="primary" size="xs" />
      <span>{profession}</span>
    </div>
    <Button className="button">
      <Icon src="assets/pages/paypipe-id/contact.svg" size="sm" />
      Get In Touch
    </Button>
    <div className="secondary-content">Send a text</div>
  </StyledCard>
);

export default UserCard;
