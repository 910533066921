import { useCallback } from "react";

import useLocation from "./useLocation";
import useNavigate from "./useNavigate";

function useHash() {
  const { pathname, search, hash } = useLocation();
  const { navigate } = useNavigate();

  const getUrlWithHash = useCallback(
    (hash: string) => {
      return `${pathname}${search}${hash}`;
    },
    [pathname, search]
  );

  const addHash = useCallback(
    (newHash: string, replace = true) => {
      navigate(getUrlWithHash(newHash), replace);
    },
    [navigate, getUrlWithHash]
  );

  const removehash = useCallback(
    (replace = true) => {
      navigate(getUrlWithHash("#"), replace);
    },
    [navigate, getUrlWithHash]
  );

  const toggleHash = useCallback(
    (newHash: string, replace = true) => {
      if (newHash === hash) {
        removehash(replace);
      } else {
        addHash(newHash, replace);
      }
    },
    [hash, removehash, addHash]
  );

  return { hash, toggleHash, getUrlWithHash, addHash, removehash };
}

export default useHash;
