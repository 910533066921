import React, { useMemo, useState } from "react";

import { StyledAvatar, StyledImage, StyledImageInput } from "./Avatar.styles";
import { IAvatarProps } from "./Avatar.types";

const Avatar: React.FC<IAvatarProps> = ({
  img,
  initials: initialsFromProps,
  className,
  size = "md",
  firstName,
  lastName,
  customSize,
  input,
}) => {
  const [hasError, setHasError] = useState(false);

  const hasImage =
    !!img && !hasError && !img.startsWith("https://gravatar.com/avatar");
  const showInitials = !hasImage || hasError;

  const initials = useMemo(() => {
    if (initialsFromProps) {
      return initialsFromProps;
    }

    const initials = [firstName, lastName]
      .filter(Boolean)
      .map((item = "") => item[0])
      .join("");

    return initials || "";
  }, [initialsFromProps, firstName, lastName]);

  if (showInitials && !initials) {
    return null;
  }

  return (
    <StyledAvatar
      $customSize={customSize}
      $size={size}
      $useImage={!showInitials}
      className={className}
    >
      {showInitials ? (
        initials
      ) : (
        <StyledImage
          referrerPolicy="no-referrer"
          $size={size}
          src={img}
          onError={() => {
            setHasError(true);
          }}
        />
      )}

      {!!input && <StyledImageInput type="file" accept="image/*" />}
    </StyledAvatar>
  );
};

export default Avatar;
