import { AxiosResponse } from "axios";
import debounce from "lodash/debounce";

import { ApiResponse, IApiService } from "@/services/Api";
import { authActions, store, userActions } from "@/services/Store";
import { removeUrlTrailingSlash } from "@/utils/string";
import { SITE_PATHS } from "@/config/routing";

import {
  IpInfo,
  OnboardStripeApiParams,
  PaymentCountryResponse,
  SubscribePremiumApiParams,
  SubscriptionDetails,
  SubscriptionDuration,
  Timezone,
  UpdateUserProfileParams,
  UserDetails,
  UserExistsResponse,
  UserInfo,
  UserInfoResponse,
  UserProfile,
  UserProfileUpdateFields,
  UserRegisterResponse,
} from "./UserService.types";

class UserService {
  _apiService: IApiService;

  constructor(_apiService: IApiService) {
    this._apiService = _apiService;
  }

  //------------------------

  registerUser = (data: Partial<Omit<UserDetails, "id">>) => {
    return this._apiService
      .post<UserRegisterResponse>("/auth/register", data)
      .then((res) => {
        store.dispatch(authActions.setUserData(res.data.data));

        return res;
      });
  };

  //--------

  fetchUserInfo_ = () => {
    return this._apiService.get<UserInfoResponse>("/user").then((res) => {
      const userData = res.data?.data?.user;

      if (userData) store.dispatch(authActions.setUserData(userData));

      return res;
    });
  };

  fetchUserInfo = debounce(this.fetchUserInfo_, 500) as () => Promise<
    AxiosResponse<UserInfoResponse, any>
  >;

  //------------------------

  updateUserInfo = (
    userInfo: UserProfileUpdateFields,
    config: UpdateUserProfileParams = {}
  ) => {
    const { notify = true } = config;

    return this._apiService
      .put<UserInfoResponse>(`/user?notify=${notify}`, userInfo)
      .then((res) => {
        const userData = res.data?.data?.user;

        if (userData) store.dispatch(authActions.setUserData(userData));

        return res;
      });
  };

  saveUserInfoToLocalStorage = (userInfo: Partial<UserProfile>) => {
    try {
      localStorage.setItem("PAYPIPE:USER_PROFILE", JSON.stringify(userInfo));
    } catch {}

    return Promise.resolve();
  };

  getUserInfoFromLocalStorage = () => {
    try {
      const userInfoString = localStorage.getItem("PAYPIPE:USER_PROFILE");
      const userInfo = (
        userInfoString ? JSON.parse(userInfoString) : {}
      ) as UserProfile;
      return userInfo;
    } catch {}

    return {} as UserProfile;
  };

  //------------------------

  onboardStripe = (params: OnboardStripeApiParams) => {
    const { countryCode } = params;
    let { refreshUrl, returnUrl } = params;

    if (!refreshUrl) {
      refreshUrl = window.location.href;
      refreshUrl = removeUrlTrailingSlash(refreshUrl);
    }

    if (!returnUrl) {
      returnUrl = window.location.href;
      returnUrl = removeUrlTrailingSlash(returnUrl);
    }

    const payload = {
      return_url: returnUrl,
      refresh_url: refreshUrl,
      country: countryCode,
    };

    return this._apiService.post<{
      url: string;
    }>("/payment/accountLink", payload);
  };

  isStripeOnboarded = () => {
    store.dispatch(
      userActions.setOnboardedState({
        isLoading: true,
      })
    );

    const isOnboarded = store.getState().user.isStripeOnboarded === true;
    if (isOnboarded) {
      return Promise.resolve(true);
    }

    return this._apiService
      .post<ApiResponse<{ onboarded: boolean; message: string }>>(
        "/payment/check-onboard-status"
      )
      .then((res) => {
        const isOnboarded = res.data.data.onboarded;

        store.dispatch(
          userActions.setOnboardedState({
            isOnboarded,
          })
        );

        return isOnboarded;
      })
      .finally(() => {
        store.dispatch(
          userActions.setOnboardedState({
            isLoading: false,
          })
        );
      });
  };

  getPaymentSupportedCountries() {
    return this._apiService.post<ApiResponse<PaymentCountryResponse>>(
      "/payment/supported-countries"
    );
  }

  checkWhetherUserExists(data: { email: string }) {
    const { email } = data;
    return this._apiService.anonGet<ApiResponse<UserExistsResponse>>(
      `/auth/user_exists?email=${email}`
    );
  }

  fetchTimezoneList() {
    return this._apiService
      .anonGet<Timezone[]>(`/assets/json/timezones.json`, { baseURL: "/" })
      .then((res) => res.data);
  }

  updateUserProfileImage(file: File) {
    const formdata = new FormData();
    formdata.append("image", file, file.name);

    return this._apiService
      .post<UserRegisterResponse>("/profile-upload", formdata)
      .then((res) => {
        store.dispatch(authActions.setUserData(res.data.data));

        return res;
      });
  }

  getClientCountry() {
    return this._apiService.anonGet<IpInfo>("", {
      baseURL: "https://ipinfo.io",
    });
  }

  //------------------------

  subscribeToPremium(params: SubscribePremiumApiParams) {
    const { interval } = params;
    let { successUrl, cancelUrl } = params;

    if (!successUrl) {
      successUrl = `${window.location.origin}${SITE_PATHS.SUBSCRIPTION_PAGE}?success=true&subscription_callback=true`;
    }

    if (!cancelUrl) {
      cancelUrl = `${window.location.origin}${SITE_PATHS.SUBSCRIPTION_PAGE}?success=false&subscription_callback=true`;
    }

    const payload = {
      successUrl,
      cancelUrl,
      interval,
    };

    return this._apiService
      .post<
        ApiResponse<{
          url: string;
        }>
      >("payment/subscribe", payload)
      .then((res) => res.data);
  }

  getPremiumSubscribtionDetails() {
    return this._apiService
      .post<ApiResponse<SubscriptionDetails>>("payment/subscription/details")
      .then((res) => {
        const data = res.data.data;

        return {
          subscriptionPlanDuration: data.plan_type,
          hasPremium: data.has_premium,
          isTrial: data.is_trial,
          subscriptionStartDate: data.start_date,
          subscriptionEndDate: data.end_date,
          status: data.status,
          availableDiscounts: data.available_discounts,
        };
      });
  }

  manageSubscription = () => {
    return this._apiService.post<
      ApiResponse<{
        url: string;
      }>
    >("payment/subscription/manage", { returnUrl: window.location.href });
  };

  cancelSubscription = () => {
    return this._apiService.post<
      ApiResponse<{
        subscripton: any;
        product: any;
        message?: string;
      }>
    >("payment/subscription/cancel");
  };

  updateSubscription = (duration: SubscriptionDuration) => {
    return this._apiService.post<ApiResponse<any>>(
      "payment/subscription/update",
      {
        newPlanType: duration,
        // source: "cancel"
      }
    );
  };

  getSubscriptionPlans = () => {
    return this._apiService.get<
      ApiResponse<{
        plans: {
          month: {
            price_cent: number;
            benefit?: number;
          };
          year: {
            price_cent: number;
            benefit?: number;
          };
        };
      }>
    >("payment/subscription/plans");
  };

  //------------------------

  updatePaypipeId = (id: string) => {
    return this._apiService.put<
      ApiResponse<{
        isValid: false;
        errors: string[];
      }>
    >(`user/paypipe-id`, {
      paypieId: id,
    });
  };

  isPaypipeIdValid = (id: string) => {
    return this._apiService.anonPost<
      ApiResponse<{
        isValid: false;
        errors: string[];
      }>
    >(`user/validate-paypipe-id/${id}`);
  };

  getUserByPaypipeId = (id: string) => {
    return this._apiService.anonGet<ApiResponse<null | UserInfo>>(
      `user/by-paypipe-id/${id}`
    );
  };

  //------------------------

  publishProfile = () => {
    return this._apiService.post<ApiResponse<boolean>>("user/profile/publish");
  };

  unpublishProfile = () => {
    return this._apiService.post<ApiResponse<boolean>>(
      "user/profile/unpublish"
    );
  };

  //------------------------

  contact = (data: { paypipeId: string; message: string }) => {
    return this._apiService.post<ApiResponse<boolean>>("user/contact", data);
  };

  //------------------------

  cleanup = () => {
    store.dispatch(
      userActions.setOnboardedState({
        isOnboarded: false,
      })
    );
  };
}

export default UserService;
