import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import SliderComponent, { Settings } from "react-slick";
import isArray from "lodash/isArray";

import { squareSizing } from "@/utils/css";
import Button from "@/components/input/Button";
import Icon from "@/components/misc/Icon";
import { joinClassNames } from "@/utils/classNames";

//-----------------------------

const StyledContainer = styled.div`
  &.slider {
    position: relative;
    display: flex;
    flex-direction: column;

    .slick-slider {
      flex-grow: 1;
    }

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .controls {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      height: 4.125rem;
      padding-inline: 1rem;

      .arrow {
        ${squareSizing("2.25rem")};
        border-radius: 10rem;
        padding: 0;
        background: rgba(255, 255, 255, 0.5);
        border: none;

        .icon {
          --size: 50%;
        }

        &.arrow-right {
          .icon {
            transform: rotate(180deg);
          }
        }
      }

      .dots {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: 100%;

        .dot {
          position: relative;
          height: 100%;
          width: 1.875rem;

          &.active {
            width: 4.375rem;

            &::before {
              background: var(--clr-primary-100, #5f57ff);
            }
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 0.75rem;
            width: 100%;
            border-radius: 10rem;
            background: #d9d8f9;
            opacity: 1;
          }
        }
      }
    }
  }
`;

var settings: Settings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplaySpeed: 0,
  touchThreshold: 100,

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

//-----------------------------

type ISliderProps = {
  children: React.ReactNode;
  className?: string;
};

//-----------------------------

const Slider: React.FC<ISliderProps> = ({
  children: childrenFromProps = [],
  className,
}) => {
  const [containerRef, setContainerRef] = useState<SliderComponent | null>(
    null
  );
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const children = useMemo(
    () =>
      isArray(childrenFromProps) ? childrenFromProps : [childrenFromProps],
    [childrenFromProps]
  );

  if (children.length === 1) {
    return <StyledContainer className={className}>{children}</StyledContainer>;
  }

  return (
    <StyledContainer className={joinClassNames("slider", className)}>
      <SliderComponent
        {...settings}
        className="slider"
        ref={setContainerRef}
        beforeChange={(_, i) => {
          setCurrentItemIndex(i);
        }}
      >
        {children.map((item, i) => (
          <div className="item-container" key={i}>
            {item}
          </div>
        ))}
      </SliderComponent>

      <div className="controls">
        <Button
          className="arrow arrow-left"
          colorVariant="white-primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (containerRef) {
              containerRef.slickPrev();
            }
          }}
        >
          <Icon
            isSrcRelative
            className="icon"
            src="chevron_left.svg"
            colorVariant="white"
            size="xxs"
          />
        </Button>

        {false && (
          <div className="dots">
            {children.map((_, i) => (
              <Button
                className={joinClassNames(
                  "dot",
                  i === currentItemIndex && "active"
                )}
                variant="ghost"
                key={i}
                onClick={() => {
                  if (containerRef) {
                    containerRef.slickGoTo(i);
                  }
                }}
              />
            ))}
          </div>
        )}

        <Button
          className="arrow arrow-right"
          colorVariant="white-primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (containerRef) {
              containerRef.slickNext();
            }
          }}
        >
          <Icon
            isSrcRelative
            className="icon"
            src="chevron_left.svg"
            colorVariant="white"
            size="xxs"
          />
        </Button>
      </div>
    </StyledContainer>
  );
};

export default Slider;
