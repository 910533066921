"use client";

import Button from "@/components/input/Button";
import {
  getViewPortHeightCssString,
  getViewPortWidthCssString,
  screenLargerThan,
} from "@/styles";
import { squareSizing } from "@/utils/css";
import styled from "@emotion/styled";

export const StyledModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: ${getViewPortWidthCssString()};
  height: ${getViewPortHeightCssString()};
  background: rgba(0, 0, 0, 0.5);
`;

export const StyledContainer = styled.div`
  position: relative;
  background: #fff;
  padding: 1.5rem;
  border-radius: 1rem;
  overflow: hidden;

  ${screenLargerThan.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledContent = styled.div`
  ${screenLargerThan.tablet} {
    flex: 1;
    height: 100%;
    overflow: hidden auto;
  }
`;

export const StyledCloseButton = styled(Button)`
  ${squareSizing("2.5rem")};
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: #fff;
  border-radius: 10rem;
`;
