"use client";

import styled from "@emotion/styled";

import Button from "@/components/input/Button";

export const StyledContainer = styled.div`
  padding-top: 1.5rem; 
  text-align: center;
  border-radius: 1rem;
  background: var(--clr-background-primary, #fff);
`;

export const StyledButton = styled(Button)`
  width: 100%;
  border: none;
  border-top: 1px solid var(--clr-border-ukwn-9, #f0f5f9);
  border-radius: 0;
  padding-block: 1rem;
`;
