import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Icon from "@/components/misc/Icon";
import { MediaFile, Portfolio } from "@/services/UserService";
import { squareSizing } from "@/utils/css";
import { useFileSignedUrl } from "@/services/ContractsService";
import ShimmerLoader from "@/components/misc/ShimmerLoader";
import Button from "@/components/input/Button";
import Slider from "@/components/misc/Slider";
import { overflowEllipsis } from "@/styles";
import Modal, { useModalState } from "@/components/misc/Modal";
import PortfolioPreview from "@/features/PaypipeId/PortfolioPreview";

//-----------------------------

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  padding: 1rem;
  height: 20.625rem;
  color: #fff;

  .top-section,
  .bottom-section {
    position: relative;
    z-index: 2;
    pointer-events: none !important;

    & > * {
      pointer-events: all;
    }
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: auto;
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-top: auto;
  }

  .title {
    ${overflowEllipsis()};
  }

  .tech {
    ${overflowEllipsis()};
    flex-grow: 1;
    font-size: 1.5rem;
    font-weight: 400;
    overflow: hidden;

    & > * {
      margin-inline-end: 0.5rem;
    }
  }

  .tag {
    display: inline-flex;
    font-size: 0.875rem;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(19.5px);
    padding: 0.5rem;
    border-radius: 10rem;
    min-width: fit-content;
  }

  .actions-section {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 0.5rem;
  }
`;

const StyledLink = styled(Link)`
  ${squareSizing("2.5rem")};
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StyledActionButton = styled(Button)`
  ${squareSizing("2.25rem")};
  border-radius: 10rem;
`;

const StyledImage = styled.div`
  position: relative;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.44));
  background-size: cover;
  background-position: center;
  height: 20.625rem;
`;

const StyledImages = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20.625rem;
  border-radius: inherit;
  z-index: 1;
  background: #777;

  & > * {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
  }
`;

//-----------------------------

type IPortfolioCardProps = {
  portfolio: Portfolio;
  className?: string;
  canEdit?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

//-----------------------------

const Image: React.FC<{ image: MediaFile }> = ({ image }) => {
  const { url, isLoading } = useFileSignedUrl(image.url || "");

  if (isLoading) {
    return (
      <ShimmerLoader.Default
        style={{ height: "20.625rem", borderRadius: "0.625rem" }}
      />
    );
  }

  return (
    <StyledImage
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.44)), url(${url})`,
      }}
    />
  );
};

const Images: React.FC<{ images: MediaFile[] }> = ({ images }) => {
  if (images.length === 1) {
    const firstImage = images[0];
    return (
      <StyledImages>
        <Image key={firstImage.id} image={firstImage} />
      </StyledImages>
    );
  }

  return (
    <StyledImages>
      <Slider>
        {images.map((image) => (
          <Image key={image.id} image={image} />
        ))}
      </Slider>
    </StyledImages>
  );
};

//-----------------------------

const PortfolioCard: React.FC<IPortfolioCardProps> = ({
  portfolio,
  className,
  canEdit,
  onDelete,
  onEdit,
}) => {
  const modalState = useModalState();
  const { name, attachments = [], link, tech } = portfolio;

  return (
    <>
      {!canEdit && (
        <Modal
          showCloseButton
          state={modalState}
          mobileProps={{ fullscreen: true }}
          width="968px"
        >
          <PortfolioPreview portfolio={portfolio} />
        </Modal>
      )}

      <StyledContainer
        className={className}
        onClick={() => {
          if (!canEdit) {
            modalState.open();
          }
        }}
      >
        <Images images={attachments} />

        <div className="top-section">
          <div className="title">{name}</div>

          {false && !!link && (
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              to={link || ""}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon isSrcRelative src="arrow_link.svg" />
            </StyledLink>
          )}
        </div>

        <div className="bottom-section">
          {!!tech?.length && (
            <div className="tech">
              {tech.map((t, i) => (
                <span key={i} className="tag">
                  {t}
                </span>
              ))}
            </div>
          )}

          {canEdit && (!!onEdit || !!onDelete) && (
            <div className="actions-section ">
              {!!onEdit && (
                <StyledActionButton
                  colorVariant="white-primary"
                  onClick={onEdit}
                >
                  <Icon
                    isSrcRelative
                    src="edit.svg"
                    size="xxs"
                    colorVariant="black"
                  />
                </StyledActionButton>
              )}
              {!!onDelete && (
                <StyledActionButton
                  colorVariant="white-primary"
                  onClick={onDelete}
                >
                  <Icon
                    isSrcRelative
                    src="delete.svg"
                    size="xxs"
                    colorVariant="black"
                  />
                </StyledActionButton>
              )}
            </div>
          )}
        </div>
      </StyledContainer>
    </>
  );
};

export default PortfolioCard;
