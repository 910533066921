import lodashGet from "lodash/get";

export const squareSizing = (size: string = "48px") =>
  ` 
    --size: ${size};
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
  `;

const PX_REGEX = /^(-?\d+(\.\d{1,2})?)px$/;
export const pxToRem = (s: string) => {
  const match = s.match(PX_REGEX);
  const number = lodashGet(match, "[1]", "");

  if (!number) {
    return "0rem";
  }

  return `${+number / 16}rem`;
};

export const getViewportBottom = () =>
  ` 
    env(safe-area-inset-bottom)
  `;
