import {
  DisplaySize,
  HeadingSize,
  LabelSize,
  BodySize,
} from "./Typography.types";

export const DISPLAY_CONFIG: Record<
  DisplaySize,
  {
    styles: React.CSSProperties;
  }
> = {
  md: {
    styles: {
      fontSize: "1.5rem",
      fontWeight: "600",
      lineHeight: "2rem",
      letterSpacing: "-0.24px",
    },
  },
  lg: {
    styles: {
      fontSize: "2rem",
      fontWeight: "600",
      lineHeight: "2.5rem",
      letterSpacing: "-0.32px",
    },
  },
  xl: {
    styles: {
      fontSize: "2.25rem",
      fontWeight: "600",
      lineHeight: "2.75rem",
      letterSpacing: "-0.36px",
    },
  },
  xxl: {
    styles: {
      fontSize: "2.5rem",
      fontWeight: "600",
      lineHeight: "4rem",
      letterSpacing: "-0.4px",
    },
  },
};

export const HEADING_CONFIG: Record<
  HeadingSize,
  {
    styles: React.CSSProperties;
  }
> = {
  overline: {
    styles: {
      fontSize: "0.75rem",
      fontWeight: "600",
      lineHeight: "1rem",
      letterSpacing: "0.72px",
    },
  },
  sm: {
    styles: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
    },
  },
  md: {
    styles: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
    },
  },
  lg: {
    styles: {
      fontSize: "1.25rem",
      fontWeight: "600",
      lineHeight: "1.75rem",
    },
  },
};

export const LABEL_CONFIG: Record<
  LabelSize,
  {
    styles: React.CSSProperties;
  }
> = {
  sm: {
    styles: {
      fontSize: "0.75rem",
      fontWeight: "600",
      lineHeight: "1.625rem",
    },
  },
  md: {
    styles: {
      fontSize: "0.875rem",
      fontWeight: "600",
      lineHeight: "1.25rem",
    },
  },
  lg: {
    styles: {
      fontSize: "1rem",
      fontWeight: "600",
      lineHeight: "1.5rem",
    },
  },
};

export const BODY_CONFIG: Record<
  BodySize,
  {
    styles: React.CSSProperties;
  }
> = {
  sm: {
    styles: {
      fontSize: "0.75rem",
      fontWeight: "400",
      lineHeight: "1rem",
    },
  },
  md: {
    styles: {
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1.25rem",
    },
  },
  lg: {
    styles: {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
    },
  },
};
