"use client";

import styled from "@emotion/styled";

import { getViewPortHeightCssString } from "@/styles";
import Button from "@/components/input/Button";
import { squareSizing } from "@/utils/css";

export const StyledMainContainer = styled.div`
  background: var(--clr-neutral-solid-25);
`;

export const StyledContainer = styled.div`
  --sidebar-width: 19rem;
  display: flex;
  flex-direction: column;
  height: ${getViewPortHeightCssString()};
  width: 100vw;
  overflow: hidden;
  max-width: 1980px;
  margin: 0 auto;
  background: var(--clr-primary-100, #5f57ff);
`;

export const StyledMain = styled.main`
  flex: 1;
  overflow: auto;
`;

export const StyledButton = styled(Button)`
  padding: 0.5rem;
`;

export const StyledIconContainer = styled.div`
  position: relative;
`;

export const StyledCount = styled.div`
  ${squareSizing("1rem")};
  border-radius: 10rem;
  background-color: var(--clr-error-400, #df1c41);
  position: absolute;
  top: 0.125rem;
  right: 0.25rem;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.65rem;
  font-weight: 400;
  color: #fff;
`;
