import React from "react";

import { ShareContent, useShare } from "@/services/Share";
import Button, { IButtonProps } from "@/components/input/Button";

export type OnClick = (params: { shareType: "custom" | "native" }) => void;

const ShareButton: React.FC<{
  content: ShareContent;
  buttonProps?: IButtonProps;
  className?: string;
  onClick?: OnClick;
}> = ({ content, className, buttonProps = {} }) => {
  const { share, modalJsx } = useShare({
    content,
  });

  return (
    <>
      {modalJsx}

      <Button className={className} {...buttonProps} onClick={share}>
        {buttonProps?.children || "Share"}
      </Button>
    </>
  );
};

export default ShareButton;
