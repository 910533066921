import useSWR from "swr";
import debounce from "lodash/debounce";

import { userService } from "@/config/services";

import { validatePaypipeId } from "../UserService.utils";

const validatePaypipeIdDebounced = debounce(
  (id: string) => userService.isPaypipeIdValid(id).then((res) => res.data.data),
  750
);

const DEFAULT_DATA = {
  errors: [] as string[],
  isValid: false,
};

function useIsValidPaypipeId({ id }: { id: string }) {
  const { data = DEFAULT_DATA, isLoading } = useSWR(
    id ? `user/validate-paypipe-id/${id}` : null,
    async () => {
      const validationData = validatePaypipeId(id);
      if (!validationData.isValid) {
        return validationData;
      }

      return validatePaypipeIdDebounced(id);
    },
    {
      errorRetryInterval: 0,
      errorRetryCount: 5,
      dedupingInterval: 0,
      fallbackData: DEFAULT_DATA,
    }
  );

  // const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState(DEFAULT_DATA);

  // useEffect(() => {
  //   setIsLoading(true);
  //   setData(DEFAULT_DATA);

  //   validatePaypipeIdDebounced(id)
  //     ?.then(setData)
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, [id]);

  return {
    data,
    isValidating: isLoading,
  };
}

export default useIsValidPaypipeId;
